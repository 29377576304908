<script lang="ts">
import { head, includes, sortBy, sumBy } from 'lodash';
import { mapStores } from 'pinia';
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

import { fetchUsers } from '@/api';
import AddChannelModal from '@/components/OverviewPage/components/AddChannel/AddChannelModal';
import AddUserModal from '@/components/Users/components/AddUsers/AddUserModal';
import { PERMISSION } from '@/Configs/Constants';
import eventBus from '@/eventBus';
import { useUserStore } from '@/store/pinia';

import Collapse from './Collapse';
import IconClass from './IconClass';
import Dropdown from './ReplyForm/Dropdown';

export default defineComponent({
  name: 'TicketFilters',
  emits: ['handle-selected-folder', 'handle-search', 'selection-changed'],
  props: {
    aggregates: {
      type: Object,
      default: () => ({}),
    },
    isSearching: {
      type: Boolean,
      default: false,
    },
    searchType: {
      type: String,
      default: 'all',
    },
    folder: {
      type: Object,
      default: () => ({}),
    },
  },
  data: function () {
    return {
      openAddChannel: false,
      openAddUser: false,
      sort: 'DESC',
      folders: [],
      visibleFilters: {
        users: [],
        labels: [],
        teams: [],
        channels: [],
        custom_views: [],
        me: [],
      },
      firstRun: true,
      aggregatesLoaded: 0,
      isLoaded: false,
      PERMISSION,
      localAggregates: {},
    };
  },

  watch: {
    isLoaded(value) {
      if (!value) return;
      const ticketFolders = this.getTicketFolders;
      /*
        @description: We need to give javascript some time to do the garbage collection, this.$nextTick should work in this case, but as we have race conditions in some other parallel components,
        it is not working as expected.
        navigating between settings and tickets has some lag, we added cachedRequest to reduce it, but still there are some room to improve the structure such as lazy routing
        or improve tructure's life cycle
      */
      setTimeout(() => {
        if (!ticketFolders?.length) {
          this.buildFolders();
        } else {
          this.folders = ticketFolders;
        }
      }, 200);
    },
    aggregates: {
      immediate: true,
      handler(val) {
        if (val) {
          this.localAggregates = { ...this.localAggregates, ...val };
          this.aggregatesLoaded++;
        }
      },
    },

    '$root.labels'() {
      this.buildFolders();
    },
    '$root.channels'() {
      this.buildFolders();
    },
    '$root.users'() {
      this.buildFolders();
    },
    '$root.customViews'() {
      this.buildFolders();
    },
  },

  components: {
    AddChannelModal,
    AddUserModal,
    Dropdown,
    Collapse,
  },

  unmounted: function () {
    eventBus.$off('tickets.statuses.fetch');
    eventBus.$off('tickets.filters.apply');
    eventBus.$off('choose-channel', this.handleChooseChannel);
  },

  mounted: function () {
    this.$nextTick(() => {
      this.isLoaded = true;
      this.getUsers();
    });
    eventBus.$on('choose-channel', this.handleChooseChannel);

    eventBus.$on('tickets.filters.apply', (type, id) => {
      this.selectFolder(
        this.groupedFolders[type].items.find((f) => {
          if (f.id) {
            return f.id === id;
          }
          return f.name === id;
        })
      );
    });
  },

  computed: {
    ...mapStores(useUserStore),
    ...mapGetters({
      getTicketFolders: 'initialData/getTicketFolders',
    }),
    computedUsers() {
      return this.groupedFolders?.users?.options || this.$root.users;
    },
    mutedChannels() {
      return this.$store.getters['userConfig/get']('channels.muted', []);
    },

    groupedFolders() {
      let grouped = {};
      this.folders.forEach((folder) => {
        if (!grouped[folder.key]) {
          grouped[folder.key] = {
            key: folder.key,
            options: folder.options,
            search_options: folder.search_options,
            display_name: folder.display_name,
            children: folder.children,
            group: folder.group,
            add_url: folder.add_url,
            items: [],
          };
        }
        grouped[folder.key].items.push(folder);
      });
      return grouped;
    },
  },

  methods: {
    getUsers() {
      fetchUsers().then((res) => {
        const { data } = res.data;
        this.$root.users = data;
      });
    },
    searchUsers(keyword) {
      const getUsersFolder = this.folders.filter((item) => item.key === 'users' && item.display_name === 'text');
      fetchUsers({ term: keyword })
        .then((res) => {
          const { data } = res.data;
          if (!getUsersFolder?.[0]?.search_options) {
            return;
          }
          this.folders = this.folders.map((item) => {
            if (item?.key === 'users' && item?.display_name === 'text') {
              item.search_options = data;
            }
            return item;
          });
        })
        .catch((err) => {
          console.error(err);
        });
    },
    handleChooseChannel({ type, id }) {
      this.$store.dispatch('onboardingSteps/putInterestsSelected', [type]);
      let route;
      if (type === 'help_center') {
        route = this.userStore.hasPermission(PERMISSION.HELP_CENTER__GENERAL__MANAGE)
          ? '/help_center'
          : '/unauthorized';
      } else {
        route = `/admin/channels2/${id}`;
      }
      eventBus.$emit('close-overview-modal');

      eventBus.$off('choose-channel', this.handleChooseChannel);
      setTimeout(() => {
        this.$router.push(route);
        eventBus.$off('close-overview-modal');
      }, 200);
    },
    handleOpenChannelsModal() {
      this.openAddChannel = true;
    },
    handleOpenUsersModal(e) {
      this.openAddUser = true;
    },
    async buildFolders() {
      let folders = [
        {
          group: this.$t('tickets.filters_inbox'),
          key: 'inbox',
        },
        {
          title: this.$t('tickets.filters_open'),
          icon: 'far fa-inbox',
          query: {
            status: 'OPEN',
            scope: 'public',
          },
          name: 'open',
          key: 'inbox',
          aggregate: {
            group: 'statuses',
            key: 'open',
          },
        },
        {
          title: this.$t('tickets.filters_assigned'),
          icon: 'far fa-user-friends',
          query: {
            status: 'ASSIGNED',
            scope: 'public',
          },
          name: 'assigned',
          key: 'inbox',
          filters: ['unreplied', 'all'],
          aggregate: {
            group: 'statuses',
            key: 'assigned',
          },
        },
        {
          title: this.$t('tickets.filters_closed'),
          icon: 'far fa-check',
          query: {
            status: 'CLOSED',
            scope: 'public',
          },
          filters: ['mine', 'all'],
          name: 'closed',
          key: 'inbox',
        },
        {
          title: this.$t('tickets.filters_spam'),
          icon: 'far fa-exclamation-circle',
          query: {
            status: 'INVALID',
            scope: 'public',
          },
          filters: ['mine', 'all'],
          name: 'spam',
          key: 'inbox',
        },
        {
          group: this.$t('tickets.filters_title_me'),
          key: 'me',
          display_name: 'text',
        },
        {
          title: this.$t('tickets.filters_assigned_to_me'),
          icon: 'far fa-user',
          query: {
            status: 'ASSIGNED',
            users: this.$root.user.id,
          },
          filters: ['unreplied', 'all'],
          visible: true,
          name: 'assigned_me',
          key: 'me',
          aggregate: {
            group: 'statuses',
            key: 'assigned_me',
          },
        },
        {
          title: this.$t('tickets.filters_mentioned'),
          icon: 'far fa-at',
          query: {
            mentioned: 'true',
          },
          filters: ['unchecked', 'checked'],
          aggregate: {
            group: 'statuses',
            key: 'mentioned',
          },
          visible: true,
          name: 'mentioned',
          key: 'me',
        },
        {
          title: this.$t('tickets.filters_favorited'),
          icon: 'far fa-star',
          query: {
            favorited: true,
          },
          visible: true,
          name: 'favorited',
          key: 'me',
          aggregate: {
            group: 'statuses',
            key: 'favorited',
          },
        },
        {
          title: this.$t('tickets.filters_reminders'),
          icon: 'far fa-alarm-clock',
          query: {
            reminders: 'true',
          },
          aggregate: {
            group: 'statuses',
            key: 'reminders',
          },
          visible: true,
          name: 'reminders',
          key: 'me',
          hideOnEmpty: true,
        },
        {
          title: this.$t('tickets.filters_watching'),
          icon: 'far fa-eye',
          query: {
            watching: 'true',
          },
          aggregate: {
            group: 'statuses',
            key: 'watching',
          },
          visible: true,
          name: 'watching',
          key: 'me',
          hideOnEmpty: true,
        },
      ];

      if (this.$root.channels.filter((c) => c.is_private).length > 0) {
        folders.push({
          title: this.$t('tickets.filters_my_channels'),
          icon: 'far fa-folder',
          query: {
            scope: 'private',
          },
          filters: ['open', 'assigned', 'closed', 'invalid'],
          aggregate: {
            group: 'me',
            key: 'all',
          },
          name: 'private_channels',
          key: 'me',
          children: this.getPersonalChannelFolders(),
        });
      }

      if (this.userStore.hasPermission(PERMISSION.INBOX__CONVERSATIONS__READ_FOR_TEAM)) {
        folders.push({
          group: this.$t('tickets.filters_teams'),
          options: this.$root.user.teams,
          key: 'teams',
          display_name: 'name',
          add_url: this.userStore.hasPermission(PERMISSION.PERMISSIONS__TEAMS__MANAGE) ? '/admin/teams' : null,
        });

        if (this.$root.user.teams !== null) {
          this.$root.user.teams.map((team, i) => {
            folders.push({
              id: team.id,
              title: team.name,
              icon: 'far fa-folder',
              query: {
                teams: team.id,
              },
              filters: ['open', 'assigned', 'closed', 'invalid'],
              key: 'teams',
              name: 'teams_' + team.id,
              aggregate: {
                group: 'teams',
                key: team.id,
              },
            });
          });
        }
      }

      folders.push({
        group: this.$t('tickets.filters_channels'),
        key: 'channels',
        options: this.$root.channels,
        search_options: this.$root.channels,
        display_name: 'text',
        add_url: this.userStore.hasPermission(PERMISSION.SETTINGS__CHANNELS__MANAGE) ? '/admin/channels2' : null,
      });

      this.$root.channels.map((channel, i) => {
        folders.push({
          id: channel.id,
          title: channel.text,
          icon: IconClass[channel.type],
          fa: true,
          query: {
            channels: channel.id,
          },
          filters: ['open', 'assigned', 'closed', 'invalid'],
          key: 'channels',
          name: 'channels_' + channel.id,
          aggregate: {
            group: 'channels',
            key: channel.id,
          },
        });
      });

      if (this.$root.customViews.length > 0) {
        folders.push({
          group: this.$t('tickets.filters_custom_views'),
          options: this.$root.customViews,
          search_options: this.$root.options,
          key: 'custom_views',
          display_name: 'title',
          add_url: this.userStore.hasPermission(PERMISSION.SETTINGS__VIEWS__MANAGE) ? '/admin/custom_views' : null,
        });

        this.$root.customViews.map((custom_view, i) => {
          folders.push({
            id: custom_view.id,
            title: custom_view.title,
            icon: 'far fa-folder',
            query: {
              custom_view_id: custom_view.id,
            },
            key: 'custom_views',
            name: 'custom_views_' + custom_view.id,
          });
        });
      }
      folders.push({
        group: this.$t('tickets.filters_labels'),
        key: 'labels',
        options: this.$root.labels,
        search_options: this.$root.labels,
        display_name: 'name',
        hideGroupOnEmpty: true,
        add_url: this.userStore.hasPermission(PERMISSION.SETTINGS__LABELS__MANAGE) ? '/admin/labels' : null,
      });

      this.$root.labels.map((label, i) => {
        folders.push({
          id: label.id,
          title: label.name,
          icon: 'far fa-folder',
          query: {
            labels: label.id,
          },
          filters: ['open', 'assigned', 'closed', 'invalid'],
          key: 'labels',
          name: 'labels_' + label.id,
          aggregate: {
            group: 'labels',
            key: label.id,
          },
        });
      });

      folders.push({
        group: this.$t('tickets.filters_users'),
        options: this.computedUsers,
        search_options: this.computedUsers,
        key: 'users',
        display_name: 'text',
        add_url: this.userStore.hasPermission(PERMISSION.PERMISSIONS__USERS__MANAGE) ? '/admin/users' : null,
      });

      sortBy(this.$root.users, 'text').map((label) => {
        folders.push({
          id: label.id,
          title: label.name,
          icon: 'far fa-folder',
          query: {
            users: label.id,
          },
          filters: ['open', 'assigned', 'closed', 'invalid'],
          key: 'users',
          name: 'users_' + label.id,
          aggregate: {
            group: 'users',
            key: label.id,
          },
        });
      });

      this.folders = folders;
      await this.$store.dispatch('initialData/setFolders', folders);

      if (this.firstRun) {
        await this.restoreVisible();
        await this.setSelected();
        this.firstRun = false;
      }
    },

    async setSelected() {
      let found = false;
      let prev = await this.$tStorage.getItem(this.$root.user.id + '_selected_folder');
      if (prev) {
        this.folders.forEach((f) => {
          if (f.name === prev && !found) {
            found = true;
            return this.selectFolder(f);
          }
          (f.children || []).forEach((f) => {
            if (f.name === prev && !found) {
              found = true;
              return this.selectFolder(f);
            }
          });
        });
      }

      if (!found) {
        this.selectFolder(this.folders.filter((f) => f.name === 'open')[0]);
      }
    },

    getPersonalChannelFolders() {
      return this.$root.channels
        .filter((c) => c.is_private)
        .map((c) => {
          return {
            id: c.id,
            title: c.title,
            icon: 'fa fa-envelope',
            fa: true,
            query: {
              channels: c.id,
            },
            filters: ['open', 'assigned', 'closed', 'invalid'],
            key: 'private_channels',
            name: 'private_channels_' + c.id,
            aggregate: {
              group: 'channels',
              key: c.id,
            },
          };
        });
    },

    async restoreVisible() {
      try {
        this.visibleFilters.users = await this.$tStorage.getItem(this.$root.user.id + '_users');
        this.visibleFilters.labels = await this.$tStorage.getItem(this.$root.user.id + '_labels');
        this.visibleFilters.channels = await this.$tStorage.getItem(this.$root.user.id + '_channels');
        this.visibleFilters.teams = await this.$tStorage.getItem(this.$root.user.id + '_teams');
        this.visibleFilters.custom_views = await this.$tStorage.getItem(this.$root.user.id + '_custom_views');
        this.visibleFilters.me = await this.$tStorage.getItem(this.$root.user.id + '_me');
      } catch (e) {
        console.error(e);
      }

      if (this.visibleFilters.users === null) {
        this.visibleFilters.users = [];
      }

      if (this.visibleFilters.labels === null) {
        this.visibleFilters.labels = [];
      }

      if (this.visibleFilters.channels === null) {
        this.visibleFilters.channels = [];
      }
      if (this.visibleFilters.teams === null) {
        this.visibleFilters.teams = [];
      }

      if (this.visibleFilters.custom_views === null) {
        this.visibleFilters.custom_views = [];
      }

      if (this.visibleFilters.me === null) {
        this.visibleFilters.me = [];
      }
    },

    async rememberVisibleFilters() {
      try {
        await this.$tStorage.setItem(this.$root.user.id + '_users', this.visibleFilters.users);
        await this.$tStorage.setItem(this.$root.user.id + '_labels', this.visibleFilters.labels);
        await this.$tStorage.setItem(this.$root.user.id + '_channels', this.visibleFilters.channels);
        await this.$tStorage.setItem(this.$root.user.id + '_teams', this.visibleFilters.teams);
        await this.$tStorage.setItem(this.$root.user.id + '_custom_views', this.visibleFilters.custom_views);
        await this.$tStorage.setItem(this.$root.user.id + '_me', this.visibleFilters.me);
      } catch (e) {}
    },

    isVisible(folder, parent_key = null) {
      if (parent_key === 'me') {
        return true;
      }

      if (parent_key) {
        return includes(this.visibleFilters[parent_key], folder.id);
      }

      // it's a group (no id)
      if (!folder.id) {
        if (folder.hideGroupOnEmpty) {
          return !!this.countForGroup(folder.key);
        }
        return true;
      }

      // is the folder's group hidden?
      let group = this.getGroupByKey(folder.key);
      if (group && !this.isVisible(group)) {
        return false;
      }

      // lastly, check if the folder is visible
      return includes(this.visibleFilters[folder.key], folder.id);
    },

    selectFolder(filter) {
      this.$tStorage.setItem(this.$root.user.id + '_selected_folder', filter.name);
      this.$emit('handle-selected-folder', filter);
      if (!this.firstRun) {
        if (this.isSearching) {
          this.$emit('handle-search');
        }
      }
    },

    getGroupByKey(key) {
      return head(this.folders.filter((i) => !i.id && i.key === key));
    },

    countForFolder(folder) {
      if (typeof folder.aggregate === 'undefined') {
        return null;
      }

      if (folder.aggregate.group === 'me') {
        return sumBy(folder.children, (f) => this.countForFolder(f));
      }

      if (typeof this.localAggregates[folder.aggregate.group] === 'undefined') {
        return null;
      }

      if (typeof this.localAggregates[folder.aggregate.group][folder.aggregate.key] === 'undefined') {
        return null;
      }

      return this.localAggregates[folder.aggregate.group][folder.aggregate.key];
    },

    countForGroup(key) {
      return this.folders.filter((i) => !!i.id && key === i.key).length;
    },

    itemIsCurrent(folder) {
      return (
        this.folder.name === folder.name && (!this.isSearching || (this.isSearching && this.searchType === 'filtered'))
      );
    },

    isMuted(channelId) {
      return this.mutedChannels.includes(channelId);
    },

    toggleMuteChannel(channelId) {
      let index = this.mutedChannels.indexOf(channelId);
      let mutedChannels = [...this.mutedChannels];

      // if muted
      if (index > -1) {
        // unmute
        mutedChannels.splice(index, 1);
      } else {
        // mute
        mutedChannels.push(channelId);
      }

      // save changes
      this.$store.dispatch('userConfig/put', { key: 'channels.muted', value: mutedChannels });
    },
    handleDropdownOnClose() {
      this.onCollapse(true);
    },
    checkSubset(parentArray, subsetArray) {
      return subsetArray.every((el) => parentArray.includes(el));
    },
    async onCollapse(showPanel) {
      if (!showPanel) {
        return;
      }

      const isChannelCollapsed = (await this.$tStorage.getItem('ticketSidebarchannels')) === 'false';
      const isLabelsCollapsed = (await this.$tStorage.getItem('ticketSidebarlabels')) === 'false';
      const isUsersCollapsed = (await this.$tStorage.getItem('ticketSidebarusers')) === 'false';

      const hasChanges =
        [
          { name: 'channels', isCollapsed: isChannelCollapsed },
          { name: 'labels', isCollapsed: isLabelsCollapsed },
          { name: 'users', isCollapsed: isUsersCollapsed },
        ].filter((field) => {
          if (field.isCollapsed) {
            return false;
          }
          return !this.checkSubset(
            Object.keys(this.localAggregates[field.name] ?? {}),
            Object.values(this.visibleFilters[field.name] ?? {}).map((i) => i + '')
          );
        }).length > 0;

      if (hasChanges) {
        this.$emit('selection-changed');
      }
    },
  },
});
</script>

<template>
  <portal to="sidebar/tickets">
    <div class="bg mt-2 select-none">
      <div v-if="$root.users.length > 0" class="">
        <div class="-mt-4 p-4 lg:pr-0">
          <div v-for="(_value, propertyName) in groupedFolders">
            <div class="l_sidebar_block mb-4 pb-2">
              <collapse :collapse-key="'ticketSidebar' + propertyName" :default-open="true" @collapse="onCollapse">
                <template #collapse-title="{ open }">
                  <div class="flex">
                    <h6 class="mb-0 flex-1" style="font-weight: 700">{{ groupedFolders[propertyName].group }}</h6>
                    <div
                      v-show="open"
                      v-if="groupedFolders[propertyName].options && groupedFolders[propertyName].options.length > 0"
                      class="ml-auto flex"
                    >
                      <dropdown
                        :options="groupedFolders[propertyName].search_options || groupedFolders[propertyName].options"
                        :label="groupedFolders[propertyName].display_name"
                        max-height="330px"
                        width="300px"
                        :dynamic-search="propertyName === 'users'"
                        :search-action="searchUsers"
                        :close-on-select="false"
                        @close="handleDropdownOnClose"
                      >
                        <template #heading>
                          <div class="_500 pt-4 text-center text-base leading-none text-black">
                            {{ groupedFolders[propertyName].group }}
                          </div>
                        </template>
                        <template #toggle>
                          <span class="text-grey-500">
                            <i class="material-icons text-md cursor-pointer hover:text-black">more_horiz</i>
                          </span>
                        </template>
                        <template #option="prop">
                          <label
                            :for="prop.option.id + '_option'"
                            class="flex cursor-pointer items-center leading-none"
                            style="margin: -10px; padding: 10px"
                          >
                            <label
                              :for="prop.option.id + '_option'"
                              class="ml-2 flex-1 cursor-pointer text-ellipsis"
                              style="padding-right: 4px; margin: 0"
                            >
                              {{ prop.option[groupedFolders[propertyName].display_name] }}
                            </label>

                            <button
                              v-if="propertyName === 'channels'"
                              class="mr-2 flex items-center leading-none text-grey-500 hover:text-grey-600"
                              @click="toggleMuteChannel(prop.option.id)"
                            >
                              <i
                                class="fa fa-fw text-md mx-1 cursor-pointer"
                                :class="{
                                  'fa-bell text-grey-500': !isMuted(prop.option.id),
                                  'fa-bell-slash text-grey-400': isMuted(prop.option.id),
                                }"
                              />
                            </button>

                            <span class="ml-auto">
                              <label class="ui-switch default" style="margin-top: 3px">
                                <input
                                  :id="prop.option.id + '_option'"
                                  v-model="visibleFilters[groupedFolders[propertyName].key]"
                                  type="checkbox"
                                  :value="prop.option.id"
                                  @change.stop="rememberVisibleFilters"
                                />
                                <i></i>
                              </label>
                            </span>
                          </label>
                        </template>
                      </dropdown>
                      <span v-if="groupedFolders[propertyName].add_url && !$root.loadedFromApp">
                        <router-link
                          v-if="propertyName !== 'channels' && propertyName !== 'users'"
                          :to="groupedFolders[propertyName].add_url"
                        >
                          <i class="text-md material-icons -mr-1 ml-2 rounded text-grey-500 hover:text-black" style="">
                            add
                          </i>
                        </router-link>
                        <span v-else-if="propertyName === 'channels'" @click="handleOpenChannelsModal(propertyName)">
                          <i class="text-md material-icons -mr-1 ml-2 rounded text-grey-500 hover:text-black" style="">
                            add
                          </i>
                        </span>
                        <span v-else-if="propertyName === 'users'" @click="handleOpenUsersModal(propertyName)">
                          <i class="text-md material-icons -mr-1 ml-2 rounded text-grey-500 hover:text-black" style="">
                            add
                          </i>
                        </span>
                        <add-channel-modal
                          v-if="
                            propertyName === 'channels' &&
                            userStore.hasPermission(PERMISSION.SETTINGS__CHANNELS__MANAGE)
                          "
                          v-model="openAddChannel"
                        />
                        <add-user-modal
                          v-if="
                            openAddUser &&
                            propertyName === 'users' &&
                            userStore.hasPermission(PERMISSION.PERMISSIONS__USERS__MANAGE)
                          "
                          v-model="openAddUser"
                        />
                      </span>
                    </div>
                  </div>
                </template>
                <template #collapse-content>
                  <div style="margin-top: 2px">
                    <div v-for="folder in groupedFolders[propertyName].items">
                      <div v-if="!folder.group" :name="aggregatesLoaded > 1 ? 'slide-fade' : ''">
                        <div
                          v-if="
                            typeof folder.hideOnEmpty === 'undefined' ||
                            localAggregates.incomplete ||
                            (folder.hideOnEmpty === true && countForFolder(folder) > 0)
                          "
                          v-show="isVisible(folder)"
                          class="collapse-menu-item flex cursor-pointer items-center rounded"
                          :class="[
                            {
                              'text-grey-700': !itemIsCurrent(folder),
                              'bg-grey-200 text-grey-900': itemIsCurrent(folder),
                            },
                            'selector-filter-' + folder.name,
                          ]"
                          role="button"
                          @click="selectFolder(folder)"
                        >
                          <div class="container-icon md-18 flex items-center" style="width: 28px">
                            <i :class="folder.icon"></i>
                          </div>
                          <span class="flex-1 text-ellipsis">{{ folder.title }}</span>
                          <span>
                            <label v-if="countForFolder(folder) > 0" class="mb-0 ml-auto text-xs">
                              {{ countForFolder(folder) }}
                            </label>
                          </span>
                        </div>
                      </div>

                      <div v-if="folder.children">
                        <div
                          v-for="sub_folder in folder.children"
                          v-show="isVisible(sub_folder, folder.key)"
                          style="padding: 8px 20px 8px 48px"
                          class="flex cursor-pointer items-center rounded"
                          :class="{
                            'text-grey-700': !itemIsCurrent(sub_folder),
                            'bg-grey-200 text-grey-900': itemIsCurrent(sub_folder),
                          }"
                          @click="selectFolder(sub_folder)"
                        >
                          <span class="flex-1 text-ellipsis">{{ sub_folder.title }}</span>
                          <span>
                            <label
                              v-if="countForFolder(sub_folder) > 0"
                              class="mb-0 ml-auto pl-2 text-right text-xs text-grey-400"
                              style="min-width: 20px"
                            >
                              {{ countForFolder(sub_folder) }}
                            </label>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </collapse>
            </div>
          </div>
        </div>
      </div>
    </div>
  </portal>
</template>

<style lang="scss">
// Trengo Theme Variables
@import 'src/assets/sass/_variables.scss';

.l_sidebar_block {
  i.collapse_icon {
    opacity: 1;
    vertical-align: middle;
    width: 14px !important;
  }
}
.l_sidebar_block h6 {
  font-size: 1rem;
}
.l_sidebar_block_title {
  margin: 0 auto;
  cursor: pointer;
  color: $t-black;
}

.l_sidebar_block_body {
}

.l_sidebar_text-muted {
  color: $t-gray;
}

@media (min-width: 1599px) {
  .mobile-ticket-filters {
    display: none;
  }
  .ticket-filters-collapse {
    display: none;
  }
}
</style>

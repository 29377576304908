<script lang="ts">
import { EditFill } from '@trengo/trengo-icons';
import _, { head, includes, map, differenceBy } from 'lodash';
import { mapStores } from 'pinia';
import { defineComponent } from 'vue';

import { useInboxTicketDrag } from '@/components/ReplyForm/hooks/useInboxTicketDrag';
import SearchRange from '@/components/SearchRange';
import { PERMISSION } from '@/Configs/Constants';
import { SETTINGS_URL } from '@/Configs/Constants/navigation/settingsUrl';
import { useUserStore } from '@/store/pinia';
import InfiniteLoading from '@/UIKit/Molecules/InfiniteLoading';
import { request } from '@/util';
import { duplicateObject } from '@/util/helpers';

import Avatar from './Avatar';
import BulkActionSelector from './BulkActionSelector';
import ChannelIcon from './ChannelIcon';
import ChannelsStatuses from './ChannelsStatuses';
import ContactProfileModal from './Contacts/ContactProfileModal.vue';
import ForwardTicketModal from './ForwardTicketModal.vue';
import MergeTicketModal from './MergeTicketModal.vue';
import Dropdown from './ReplyForm/Dropdown';
import SearchBox from './SearchBox';
import TicketResultLimiter from './TicketFilter';
import ticketFilters from './TicketFilters';
import TicketResultModal from './TicketResultModal.vue';
import WaMigrationBanner from './WaMigrationBanner';
import PusherHelper from '../mixins/PusherHelper';
import ticketRepository from '../repositories/Ticket';

export default defineComponent({
  name: 'Tickets',

  components: {
    SearchRange,
    Dropdown,
    ticketFilters,
    ChannelIcon,
    Avatar,
    ForwardTicketModal,
    TicketResultModal,
    ContactProfileModal,
    BulkActionSelector,
    MergeTicketModal,
    ChannelsStatuses,
    InfiniteLoading,
    SearchBox,
    EditFill,
    WaMigrationBanner,
  },

  mixins: [PusherHelper],

  data: function () {
    return {
      showSearch: false,
      folder: {},
      subFolder: null,
      tickets: [],
      count: 0,
      busy: false,
      filters: {
        sort: '-date',
        status: 'OPEN',
      },
      searchRange: 'last-week',
      page: 0,
      filtering: true,
      selected: [],
      hover: null,
      activeUnchecked: false,
      aggregates: {
        incomplete: false,
      },
      showBulkSelect: false,
      isSearching: false,
      searchType: 'all',
      lastSelectedTicket: null,
      paginationData: null,
      infiniteId: 0,
      SETTINGS_URL,
      PERMISSION,
    };
  },
  async created() {
    this.filters.sort = (await this.$tStorage.getItem(this.$root.user.id + '_filters.sort')) || '-date';
  },
  computed: {
    ...mapStores(useUserStore),
    selectionMode() {
      return this.selected.length > 0;
    },

    hasBulkPermission() {
      return this.userStore.hasAnyPermission([
        PERMISSION.INBOX__CONVERSATIONS__CREATE,
        PERMISSION.INBOX__CONVERSATIONS__ASSIGN,
        PERMISSION.INBOX__CONVERSATIONS__CLOSE,
        PERMISSION.INBOX__CONVERSATIONS__DELETE,
      ]);
    },

    filteredTickets() {
      return TicketResultLimiter.setFilters(this.filters)
        .setUserId(this.$root.user.id)
        .setIsSearching(this.isSearching)
        .setTickets(this.tickets)
        .getFilteredResults();
    },
    filteredTicketsWithContact() {
      return this.filteredTickets.filter((ticket) => ticket.contact != null);
    },
  },

  watch: {
    $route(route) {
      if (route.name === 'tickets-index') {
        this.$root.last_ticket_id = null;
        if (!this.filteredTickets.length) {
          // if list is not loaded since we started from a Ticket instead of ticket overview:
          this.infiniteId++;
        }
      }
    },
    async folder(folder) {
      let selected = await this.$tStorage.getItem(this.$root.user.id + '_selected_subfilter_' + folder.name);
      if (selected && folder.filters && folder.filters.includes(selected)) {
        this.subfilter(selected);
      } else {
        if (folder.filters) {
          this.subfilter(folder.filters.includes('all') ? 'all' : folder.filters[0]);
        } else {
          this.onFiltered();
        }
      }
    },
    'selected.length'(length, previous) {
      eventBus.$emit('ticket.bulk_selected', length);
      if (length === 0) {
        this.lastSelectedTicket = null;
      }
    },
    searchRange() {
      this.focus = false;
      this.search();
    },
  },
  mounted: function () {
    this.registerFilters();
    this.registerOfflineHandler();
    this.bindEvents();

    eventBus.$on('interval-resume', this.onAppResume);

    eventBus.$on('start-conversation-clicked', () => {
      eventBus.$emit('modals.create-ticket.open');
    });
  },

  unmounted: function () {
    this.unBindEvents();
    TicketOverviewChannel.unbind('RELOAD_OVERVIEW');

    eventBus.$off('tickets.filters.onStatusChanged');
    eventBus.$off('tickets.filters.onFiltersChanged');
    eventBus.$off('tickets.filters.onScopeChanged');
    eventBus.$off('tickets.filters.onSortOrderChanged');
    eventBus.$off('tickets.list.reload');
    eventBus.$off('tickets.search');

    this.removeOfflineHandler();

    eventBus.$off('interval-resume', this.onAppResume);

    eventBus.$off('start-conversation-clicked');
  },

  setup() {
    const { isDraggingTicketSidebar, ticketSidebarWidth, startDrag } = useInboxTicketDrag();

    if (sessionStorage?.ticketSidebarWidth) {
      ticketSidebarWidth.value = Number(sessionStorage?.ticketSidebarWidth);
    }

    return {
      isDraggingTicketSidebar,
      ticketSidebarWidth,
      startDrag,
    };
  },
  methods: {
    bindEvents() {
      TicketOverviewChannel.bind('TICKET_UPDATED', (data) => this.ticketUpdated(data));
      TicketOverviewChannel.bind('TICKET_UPDATED_BULK', (data) => this.ticketUpdatedBulk(data));
      TicketOverviewChannel.bind('RELOAD_OVERVIEW', (data) => this.reloadOverview(data));
      this.bindToEventOnTeamAndUserChannels('TICKET_UPDATED', (data) => this.ticketUpdated(data));
      this.bindToEventOnTeamAndUserChannels('TICKET_UPDATED_BULK', (data) => this.ticketUpdatedBulk(data));
      this.bindToEventOnTeamAndUserChannels('RELOAD_OVERVIEW', (data) => this.reloadOverview(data));

      eventBus.$on('tickets.list.reload', (data) => {
        this.onFiltered();
      });

      eventBus.$on('ticket.unauthorized', (ticketId) => {
        this.removeTicketFromList(ticketId);
      });

      this.bindPusherEvent(this.$root.userChannel, 'ticket-mention-deleted', () => {
        /*
         TICKET_AGGREGATES_CACHE_TTL is set 15 seconds in backend.
         So, if we call /ticket_aggregates before 15s, we will get old data.
        */
        setTimeout(() => {
          this.fetchAggregates();
        }, 16000);
      });
    },
    unBindEvents() {
      TicketOverviewChannel.unbind('RELOAD_OVERVIEW');
      this.unBindToEventOnTeamAndUserChannels('TICKET_UPDATED');
      this.unBindToEventOnTeamAndUserChannels('TICKET_UPDATED_BULK');
      this.unBindToEventOnTeamAndUserChannels('RELOAD_OVERVIEW');

      eventBus.$off('tickets.filters.onStatusChanged');
      eventBus.$off('tickets.filters.onFiltersChanged');
      eventBus.$off('tickets.filters.onScopeChanged');
      eventBus.$off('tickets.filters.onSortOrderChanged');
      eventBus.$off('ticket.unauthorized');
      eventBus.$off('tickets.list.reload');
      eventBus.$off('tickets.search');

      this.removeOfflineHandler();

      this.unbindPusherEvent(this.$root.userChannel, 'ticket-mention-deleted');
    },

    removeTicketFromList(ticketId) {
      this.tickets = this.tickets.filter((ticket) => ticket.id !== ticketId);
    },

    ticketUpdated(data) {
      if (this.busy) {
        return;
      }

      if (data.force_reload) {
        this.onFiltered();
        return;
      }

      if (data.targets.indexOf(this.$root.user.id) === -1) {
        return true;
      }

      let ticket = this.tickets.filter((ticket) => ticket.id == data.ticket.id)[0];

      // Append when not in current list
      if (typeof ticket === 'undefined' && !this.isSearching) {
        if (this.filters.sort !== '-date') {
          // Append to end of list (only if on page 1)
          if (this.filteredTickets.length < this.paginationData.per_page) {
            const findTicket = this.tickets.filter((ticket_item) => ticket_item.id === data.ticket.id);
            if (findTicket.length === 0) {
              this.tickets.push(data.ticket);
            }
          }
        } else {
          this.tickets.unshift(data.ticket);
        }
      }

      // Or update
      if (typeof ticket !== 'undefined') {
        if (data.ticket.trashed) {
          this.tickets.splice(this.tickets.indexOf(ticket), 1);
        }

        // Manually set properties which are deep, otherwise the computed is not reactive :(
        [
          'starred',
          'labels',
          'teams',
          'watchers',
          'mentioned_checked',
          'mentioned_unchecked',
          'reminders',
          'status',
          'user_id',
          'team_id',
        ].forEach((prop) => {
          Object.assign(ticket, {
            [prop]: data.ticket[prop],
          });
        });

        Object.assign(ticket, data.ticket);
      }

      // If currently in active in a custom view, just do a server reload (frontend is to complex for now)
      if (this.filters.custom_view_id) {
        this.onFiltered();
      }

      // Update aggregates
      if (data.update_aggregates) {
        eventBus.$emit('tickets.statuses.fetch');
      }

      this.$nextTick(() => {
        // try to load the next page whenever we have exactly 30 tickets (without this code block, you would
        // miss tickets because the next page does not load automatically)
        if (this.filteredTickets.length < this.paginationData.per_page && this.paginationData.next !== null) {
          this.fetchData();
        }
      });
    },

    ticketUpdatedBulk(data) {
      data.forEach((t) => {
        let ticket = head(this.tickets.filter((ticket) => ticket.id == t.ticket.id));
        if (!ticket || !t.ticket) {
          this.onFiltered();
          return;
        }
        if (t.ticket.trashed) {
          Object.assign(ticket, { trashed: true });
        }
        Object.assign(ticket, t.ticket);
      });
    },

    reloadOverview(data) {
      if (this.busy) {
        return;
      }
      this.onFiltered();
      return;
    },

    registerOfflineHandler() {
      Offline.on('up', () => {
        this.page = 0;
        this.tickets = [];
        this.onFiltered();
      });
    },

    removeOfflineHandler() {},

    fetchAggregates: _.throttle(async function () {
      const _channels = await this.$tStorage.getItem(this.$root.user.id + '_channels');
      const labels = await this.$tStorage.getItem(this.$root.user.id + '_labels');
      const users = await this.$tStorage.getItem(this.$root.user.id + '_users');

      const isChannelCollapsed = (await this.$tStorage.getItem('ticketSidebarchannels')) === 'false';
      const isLabelsCollapsed = (await this.$tStorage.getItem('ticketSidebarlabels')) === 'false';
      const isUsersCollapsed = (await this.$tStorage.getItem('ticketSidebarusers')) === 'false';

      // always send the private channel id's in query so that in can populate in the Personal section
      const channels = this.$root.channels.filter((c) => c.is_private).map((c) => c.id) ?? [];

      if (!isChannelCollapsed && _channels) {
        channels.push(..._channels);
      }

      const query = {
        channels: channels ? [...new Set(channels)] : null,
        labels: !isLabelsCollapsed && labels ? labels : null,
        users: !isUsersCollapsed && users ? users : null,
      };

      try {
        await request('/api/v2/ticket_aggregates', 'GET', {}, {}, query).then((res) => {
          if (res) {
            this.aggregates = res.data;

            // filter out things we didn't query for
            this.aggregates = Object.fromEntries(
              Object.entries(this.aggregates).filter(([_, v]) => {
                return Object.keys(v).length > 0;
              })
            );
            // this sets 0 to empty folders
            Object.keys(this.aggregates).forEach((field) => {
              query[field]
                ?.filter((r) => !Object.keys(this.aggregates[field]).includes(r + ''))
                .forEach((key) => {
                  this.aggregates[field][key] = 0;
                });
            });
          }
        });
      } catch (e) {
        console.error(e);
      }
    }, 5000),

    handleSelectionChanged() {
      this.fetchAggregates();
    },

    registerFilters: function () {
      this.fetchAggregates();

      eventBus.$on('tickets.statuses.fetch', () => {
        this.fetchAggregates();
      });

      eventBus.$on('tickets.search', (term) => {
        this.filters.term = term;
        this.search();
      });

      eventBus.$on('tickets.filters.onSortOrderChanged', (data) => {
        this.filters.sort = data.sort;
        this.onFiltered(true);
      });
    },

    fetchData: function (scroll) {
      this.page++;
      this.busy = true;
      this.activeUnchecked = false;

      let filters = duplicateObject(this.filters);

      if (this.isSearching && this.searchType === 'all') {
        filters.scope = null;
        filters.last_message_type = null;
        filters.status = null;
        filters.labels = null;
        filters.channels = null;
        filters.users = null;
        filters.teams = null;
        filters.favorited = null;
        filters.mentioned = null;
        filters.custom_view_id = null;
        filters.reminders = null;
        filters.feed = null;
        filters.channels_scope = null;
      }

      ticketRepository
        .getAllTickets(this.page, { searchRange: this.searchRange, ...filters })
        .then((res) => {
          this.paginationData = res.data.links;
          this.paginationData.per_page = res.data.meta.per_page;
          const newTickets = res.data.data;

          const nonDuplicatedTickets = differenceBy(newTickets, this.tickets, 'id');
          this.tickets.push(...nonDuplicatedTickets);

          this.filtering = false;
          this.busy = false;
          this.selected = this.selected.filter((s) => {
            return map(this.tickets, 'id').indexOf(s) !== -1;
          });
          if (scroll) {
            this.$nextTick(() => {
              scroll.loaded();
              if (res.data.links.next === null) {
                scroll.complete();
              }
            });
          }
        })
        .catch(() => {
          this.filtering = false;
          this.busy = false;
        });
    },

    onFiltered: function () {
      this.filtering = true;
      this.loading = true;
      this.page = 0;
      this.tickets = [];
      this.selected = [];
      this.lastSelectedTicket = null;
      this.infiniteId += 1;
      eventBus.$emit('hideNav');
    },

    isTransferredTeamTicket(ticket) {
      return ticket.status == 'OPEN' && ticket.team_id != null && !this.$root.isInTeam(ticket.team_id);
    },

    handleMetaClick(ticket, e) {
      if (!this.hasBulkPermission) {
        return;
      }
      this.toggleCheck(ticket);

      if (this.isSelected(ticket)) {
        this.lastSelectedTicket = ticket;
      }
    },

    handleShiftClick(ticket, e) {
      if (!this.hasBulkPermission) {
        return;
      }
      let clickedIndex = this.tickets.indexOf(ticket);

      if (!this.lastSelectedTicket) {
        this.toggleCheck(ticket, true);
        if (this.isSelected(ticket)) {
          this.lastSelectedTicket = ticket;
        }
        return;
      }

      let lastSelectedIndex = this.tickets.indexOf(this.lastSelectedTicket);
      if (lastSelectedIndex === -1) {
        this.toggleCheck(this.tickets[clickedIndex], true);
        this.lastSelectedTicket = null;
        return;
      }

      let ticketWasSelected = this.isSelected(ticket);
      let cachedArray = [...this.tickets];
      cachedArray.forEach((t, index) => {
        if (
          (!ticketWasSelected &&
            ((index <= clickedIndex && index >= lastSelectedIndex) ||
              (index >= clickedIndex && index <= lastSelectedIndex))) ||
          (ticketWasSelected &&
            ((index < clickedIndex && index >= lastSelectedIndex) ||
              (index > clickedIndex && index <= lastSelectedIndex))) // don't deselect clicked ticket
        ) {
          this.toggleCheck(t, true, !ticketWasSelected);
        }
      });
      if (this.isSelected(ticket)) {
        this.lastSelectedTicket = ticket;
      }
    },

    onTicketHover(ticket) {
      this.hover = ticket.id;
    },

    onTicketHoverOut(ticket) {
      this.hover = null;
    },

    toggleCheck(ticket, splice = true, assureState = null) {
      if (!this.hasBulkPermission) {
        return;
      }
      if (this.$root.pendingBulk) {
        return;
      }

      if (this.selected.indexOf(ticket.id) == -1 && assureState !== false) {
        this.selected.push(ticket.id);
      } else if (assureState !== true) {
        if (splice) {
          this.selected.splice(this.selected.indexOf(ticket.id), 1);
        }
      }
    },

    onItemClick(ticket, e) {
      if (e.shiftKey || e.ctrlKey || e.metaKey) {
        return;
      }

      if (this.selected && this.selected.length > 0) {
        this.toggleCheck(ticket);
        return;
      }

      if ($(e.target).hasClass('ticket-check-area')) {
        this.toggleCheck(ticket);
        if (this.isSelected(ticket)) {
          this.lastSelectedTicket = ticket;
        }
      } else {
        this.lastSelectedTicket = null;
        router.push('/tickets/' + ticket.id);
      }
    },

    isSelected(ticket) {
      return this.selected.indexOf(ticket.id) !== -1;
    },

    setSortOrder(order, reSearch = true) {
      this.filters.sort = order;
      if (reSearch) {
        eventBus.$emit('tickets.filters.onSortOrderChanged', { sort: this.filters.sort });
      }
      if (this.isSearching) {
        this.$tStorage.setItem(this.$root.user.id + '_filters.search.sort', this.filters.sort);
      } else {
        this.$tStorage.setItem(this.$root.user.id + '_filters.sort', this.filters.sort);
      }
    },

    openCreateTicketModal: function () {
      eventBus.$emit('modals.create-ticket.open');
    },

    toggleSelectAll() {
      if (this.selected.length !== this.filteredTickets.length) {
        this.selected = this.filteredTickets.map((t) => {
          return t.id;
        });
      } else {
        this.selected = [];
        this.lastSelectedTicket = null;
      }
    },

    onBulkMouseOver() {
      if (this.tickets.length > 0) {
        this.showBulkSelect = true;
      }
    },

    onBulkMouseOut() {
      this.showBulkSelect = false;
    },

    subfilter(filter) {
      this.subFolder = filter;

      this.$tStorage.setItem(this.$root.user.id + '_selected_subfilter_' + this.folder.name, filter);

      if (filter === 'mine') {
        this.filters.users = this.$root.user.id;
      }
      if (filter === 'open') {
        this.filters.status = 'OPEN';
      }
      if (filter === 'assigned') {
        this.filters.status = 'ASSIGNED';
      }

      if (filter === 'invalid') {
        this.filters.status = 'INVALID';
      }

      if (filter === 'checked') {
        this.filters.mentioned = 'checked';
      }

      if (filter === 'unchecked') {
        this.filters.mentioned = 'unchecked';
      }

      if (filter === 'closed') {
        this.filters.status = 'CLOSED';
      }

      if (filter === 'all' && this.folder.name === 'mentioned') {
        this.filters.mentioned = 'all';
      }

      this.filters.last_message_type = filter === 'unreplied' ? 'INBOUND' : null;

      if (filter === 'all') {
        if (this.isSearching || typeof this.folder.query.status === 'undefined') {
          this.filters.status = null;
        }
        if (typeof this.folder.query.users === 'undefined') {
          this.filters.users = null;
        }
      }

      this.onFiltered(true);
    },

    transSubFilter(folder) {
      switch (folder) {
        case 'mine':
          return this.$t('tickets.filters_mine');
        case 'all':
          return this.$t('tickets.filters_all');
        case 'open':
          return this.$t('tickets.filters_open');
        case 'assigned':
          return this.$t('tickets.filters_assigned');
        case 'closed':
          return this.$t('tickets.filters_closed');
        case 'unreplied':
          return this.$t('tickets.filters_unreplied');
        case 'replied':
          return this.$t('tickets.filters_replied');
        case 'unchecked':
          return this.$t('tickets.filters_unchecked');
        case 'checked':
          return this.$t('tickets.filters_checked');
        case 'invalid':
          return this.$t('tickets.filters_spam');
      }
    },

    async search() {
      this.$tStorage.setItem('filters.term', this.filters.term);
      if (this.filters.term.length > 0) {
        this.isSearching = true;
        if (!this.isSearching) {
          this.setSortOrder(
            (await this.$tStorage.getItem(this.$root.user.id + '_filters.search.sort')) || '-date',
            false
          );
        }
      } else {
        this.searchType = 'filtered';
        this.isSearching = false;
        this.searchRange = 'last-week';
      }
      this.onFiltered();
    },

    setSearchType(type) {
      this.focus = false;
      this.searchType = type;
      this.search();
    },

    resetSearch() {
      this.filters.term = '';
      this.searchRange = 'last-week';
      this.search();
    },

    showAll() {
      this.subfilter('all');
    },

    hasAll() {
      return includes(this.folder.filters, 'all');
    },

    isActive(ticket) {
      return this.$route.params.ticketId == ticket.id;
    },

    isImportant(ticket) {
      return (
        (ticket.status === 'ASSIGNED' || ticket.status === 'OPEN') &&
        (ticket.channel || {}).type === 'CHAT' &&
        ticket.latest_message != null &&
        ticket.latest_message.type === 'INBOUND' &&
        ticket.status !== 'CLOSED'
      );
    },

    reload() {
      window.location.reload(true);
    },

    onSwipeRight() {
      if (!is_mobile_device()) return;
      eventBus.$emit('showNav');
    },

    channelIsPrivate(channel_id) {
      return (this.$root.channels.find((c) => c.id === channel_id) || {}).is_private;
    },

    onAppResume() {
      this.onFiltered();
      eventBus.$emit('tickets.statuses.fetch');
    },

    createConversation() {
      eventBus.$emit('start-conversation-clicked');
    },
    stripHtml(str) {
      if (str == null) {
        return;
      }
      return str.replace(/<\/?[^>]+(>|$)/g, '');
    },
    updateSelected(value) {
      this.selected = value;
    },
    handleSelectedFolder(filter) {
      this.folder = filter;
      let filters = { ...this.filters };
      this.filters = { sort: filters.sort || '-date', ...filter.query };
    },
    handleSearch() {
      this.isSearching = false;
      this.term = '';
      this.onFiltered();
    },
  },
  provide() {
    return {
      tickets: this.tickets,
    };
  },
});
</script>

<template>
  <div class="flex w-full min-w-0 flex-1 bg-white">
    <portal v-if="userStore.hasPermission(PERMISSION.INBOX__CONVERSATIONS__CREATE)" to="sidebar/bottom">
      <t-button
        type="button"
        class="hidden-md-down selector-create-conversation m-auto my-4 flex items-center p-3 px-4"
        btn-style="secondary"
        data-test="start-conversation-button"
        @click="createConversation()"
      >
        <edit-fill class="mr-1" size="16" />
        {{ $t('tickets.start_conversation') }}
      </t-button>
    </portal>
    <ticket-filters
      v-if="$root.users.length > 0"
      ref="ticketFilters"
      :aggregates="aggregates"
      :is-searching="isSearching"
      :search-type="searchType"
      :folder="folder"
      @handle-selected-folder="handleSelectedFolder"
      @handle-search="handleSearch"
      @selection-changed="handleSelectionChanged"
    ></ticket-filters>
    <div
      :style="`width: ${ticketSidebarWidth}px;`"
      class="tickets_list flex min-w-0 select-none flex-col"
      :class="{ 'hidden-md-down': $route.path != '/tickets' }"
    >
      <div class="flex w-full flex-col pt-12 lg:pt-4">
        <div id="Updater" class="bg-info mx-4 mb-2 flex cursor-pointer rounded-lg p-4 pt-2 text-white" @click="reload">
          <div class="ml-2">
            <h5 class="mb-1">{{ $t('general.update_available') }}</h5>
            <div>{{ $t('general.update_available_instructions') }}</div>
          </div>
        </div>
        <wa-migration-banner />
        <channels-statuses></channels-statuses>
        <div class="hidden-md-down">
          <div class="mt-4 flex items-center px-4 pb-0">
            <search-box v-model="filters.term" :placeholder="$t('general.searching')" @search="search"></search-box>
          </div>
          <div v-show="isSearching" class="px-4 pt-4">
            <div class="flex items-center justify-center bg-grey-200" style="border-radius: 10px">
              <span
                :class="{ 'ticket-sub-filter--active': searchType === 'filtered' }"
                class="ticket-sub-filter flex-1 text-center text-sm text-grey-600"
                @click="setSearchType('filtered')"
              >
                In "
                <span v-if="folder.title">{{ folder.title.substr(0, 15) }}</span>
                "
              </span>
              <span
                :class="{ 'ticket-sub-filter--active': searchType === 'all' }"
                class="ticket-sub-filter flex-1 text-center text-sm text-grey-600"
                @click="setSearchType('all')"
              >
                {{ $t('tickets.search_all') }}
              </span>
            </div>
            <search-range v-model="searchRange" class="mt-4" />
          </div>
        </div>
        <div
          class="inbox mb-2 flex w-full min-w-0 flex-1 items-center border-b-2 border-grey-200 p-4 lg:m-0 lg:border-0"
        >
          <div
            class="mr-0 flex-shrink-0 items-center text-center lg:mr-3"
            style="width: 45px; height: 29px; overflow: hidden"
            @mouseover="onBulkMouseOver"
            @mouseout="onBulkMouseOut"
          >
            <div class="hidden-md-down flex-1 pl-4">
              <label
                v-show="(selectionMode || showBulkSelect) && hasBulkPermission"
                class="md-check ticket-check-area"
                :class="{ checked: selected.length === filteredTickets.length }"
                style="top: 2px; padding-top: 0px; margin-left: 19px"
                @click="toggleSelectAll"
              >
                <i class="green ticket-check-area"></i>
              </label>
            </div>
          </div>
          <div v-show="selected.length == 0" class="flex w-full min-w-0 items-center">
            <div class="_700 text-ellipsis text-base" style="margin-top: -4px">
              <div v-if="!isSearching || (isSearching && searchType !== 'all')" class="flex">
                <span class="text-ellipsis">
                  {{ folder.title }}
                </span>
                <span
                  v-if="
                    folder.aggregate &&
                    aggregates &&
                    aggregates[folder.aggregate.group] &&
                    aggregates[folder.aggregate.group][folder.aggregate.key]
                  "
                  class="hidden-lg-up ml-1 text-grey-500"
                >
                  {{ aggregates[folder.aggregate.group][folder.aggregate.key] }}
                </span>
              </div>
              <div v-else>{{ $t('tickets.search_all') }}</div>
            </div>
            <div class="ml-auto flex flex-shrink-0 items-center pl-3 text-grey-600">
              <i class="material-icons hidden-lg-up mr-4" @click="showSearch = !showSearch">search</i>
              <div class="btn-group sort-group mr-0">
                <dropdown
                  :static="true"
                  :search="false"
                  :width="'auto'"
                  :min-width="'200px'"
                  :auto-height="true"
                  :scroll-on-hover="false"
                >
                  <template #toggle>
                    <i
                      class="material-icons cursor-pointer select-none"
                      :style="filters.sort === 'date' ? 'transform: rotateX(180deg)' : ''"
                    >
                      sort
                    </i>
                  </template>
                  <template #heading>
                    <div
                      class="
                        _500
                        hidden-lg-up
                        select-none
                        border-grey-200
                        py-4
                        text-center text-base
                        leading-none
                        text-black
                      "
                    >
                      {{ $t('general.nav_options') }}
                    </div>
                  </template>
                  <template #body>
                    <div
                      v-show="isSearching"
                      class="align-items flex cursor-pointer p-4 px-5 leading-none text-black hover:bg-grey-200"
                      :class="{ active: filters.sort == 'score' }"
                      @click="setSortOrder('score')"
                    >
                      {{ $t('tickets.sort_relevance') }}
                    </div>
                    <div
                      class="align-items flex cursor-pointer p-4 px-3 px-5 leading-none text-black hover:bg-grey-200"
                      :class="{ active: filters.sort == '-date' }"
                      @click="setSortOrder('-date')"
                    >
                      {{ $t('tickets.sort_newest') }}
                    </div>
                    <div
                      class="align-items flex cursor-pointer p-4 px-3 px-5 leading-none text-black hover:bg-grey-200"
                      :class="{ active: filters.sort == 'date' }"
                      @click="setSortOrder('date')"
                    >
                      {{ $t('tickets.sort_oldest') }}
                    </div>
                  </template>
                </dropdown>
              </div>
            </div>
          </div>
          <div v-show="selected.length > 0" class="flex flex-1 items-center">
            <bulk-action-selector
              v-show="selected.length > 0"
              :selection="selected"
              :status="filters.status"
              @update-selected="updateSelected"
            ></bulk-action-selector>
          </div>
        </div>
        <div v-if="showSearch" class="hidden-lg-up -mb-2">
          <div class="mb-4 mt-2 flex items-center px-4 pb-0">
            <search-box v-model="filters.term" :placeholder="$t('general.searching')" @search="search"></search-box>
          </div>
          <div v-show="isSearching" class="mb-6 px-4">
            <div class="flex items-center justify-center bg-grey-200" style="border-radius: 10px">
              <span
                data-test="search-in-toggle"
                :class="{ 'ticket-sub-filter--active': searchType === 'filtered' }"
                class="ticket-sub-filter flex-1 text-center text-sm text-grey-600"
                @click="setSearchType('filtered')"
              >
                In "
                <span v-if="folder.title">{{ folder.title.substr(0, 15) }}</span>
                "
              </span>
              <span
                data-test="search-all-toggle"
                :class="{ 'ticket-sub-filter--active': searchType === 'all' }"
                class="ticket-sub-filter flex-1 text-center text-sm text-grey-600"
                @click="setSearchType('all')"
              >
                {{ $t('tickets.search_all') }}
              </span>
            </div>
            <search-range v-model="searchRange" class="mt-4" />
          </div>
        </div>
        <div v-show="!isSearching && folder.filters && folder.filters.length" class="mt-2 px-4 pb-4 lg:mt-0" style="">
          <div class="flex items-center justify-center bg-grey-200" style="border-radius: 10px">
            <span
              v-for="(filter, i) in folder.filters"
              :key="i"
              class="ticket-sub-filter flex-1 text-center text-sm text-grey-600"
              :class="[
                { 'ticket-sub-filter--active': filter == subFolder, ' ': filter != subFolder },
                'selector-sub-filter-' + filter,
              ]"
              role="button"
              @click="subfilter(filter)"
            >
              {{ transSubFilter(filter) }}
            </span>
          </div>
        </div>
      </div>
      <div class="scroll-on-hover flex flex-1 flex-row lg:p-4 lg:pt-0" infinite-wrapper data-list="tickets">
        <div class="w-full min-w-0 flex-1">
          <div v-show="filtering" class="flex h-full flex-1 items-center justify-center text-center">
            <div
              class="shadow"
              style="
                width: 45px;
                height: 45px;
                background: white;
                border-radius: 100%;
                overflow: hidden;
                display: flex;
                align-items: center;
                justify-content: center;
              "
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                style="margin: auto; background: rgb(255, 255, 255); display: block; shape-rendering: auto"
                width="34px"
                height="34px"
                viewBox="0 0 100 100"
                preserveAspectRatio="xMidYMid"
              >
                <circle
                  cx="50"
                  cy="50"
                  fill="none"
                  stroke="#14B29F"
                  stroke-width="10"
                  r="35"
                  stroke-dasharray="164.93361431346415 56.97787143782138"
                  transform="rotate(284.393 50 50)"
                >
                  <animateTransform
                    attributeName="transform"
                    type="rotate"
                    repeatCount="indefinite"
                    dur="1s"
                    values="0 50 50;360 50 50"
                    keyTimes="0;1"
                  ></animateTransform>
                </circle>
              </svg>
            </div>
          </div>
          <div
            v-if="filteredTickets.length == 0 && !filtering"
            class="flex h-full flex-1 items-center justify-center text-center text-base"
          >
            <span class="_500 text-lg text-grey-500">{{ $t('tickets.no_tickets_found') }}</span>
          </div>
          <div id="ticket-list" class="inbox list-item-new-style">
            <div
              v-for="(ticket, i) in filteredTicketsWithContact"
              :key="i"
              data-test="ticket-list-item"
              class="list-item min-w-0 cursor-pointer select-none overflow-hidden"
              :class="{
                'important-ticket': isImportant(ticket),
                active: isSelected(ticket) || (isActive(ticket) && !selectionMode),
                'opacity-50': isTransferredTeamTicket(ticket),
                'no-select': selectionMode,
                'list-item-no-border-bottom': i === filteredTickets.length - 1,
              }"
              @click="onItemClick(ticket, $event)"
            >
              <div
                class="list-container flex min-w-0 pl-1 lg:p-0"
                @click.meta="handleMetaClick(ticket, $event)"
                @click.ctrl="handleMetaClick(ticket, $event)"
                @click.shift="handleShiftClick(ticket, $event)"
              >
                <div class="items-center" style="margin-top: 17px">
                  <div
                    data-hj-suppress
                    style="width: 45px; height: 50px"
                    @mouseenter="hover = ticket.id"
                    @mouseleave="hover = null"
                  >
                    <avatar
                      v-if="(hover !== ticket.id && !isSelected(ticket) && !selectionMode) || !hasBulkPermission"
                      class="ml-3"
                      :width="32"
                      font-size="12px"
                      :color="ticket.contact.color"
                      :abbr="ticket.contact.abbr"
                      :image="ticket.contact.profile_image"
                    ></avatar>
                    <span
                      v-if="(hover === ticket.id || isSelected(ticket) || selectionMode) && hasBulkPermission"
                      class="ticket-check-area"
                    >
                      <label
                        class="md-check ticket-check-area"
                        style="padding: 0 0 0 28px; width: 44px; top: 4px"
                        :class="{ checked: isSelected(ticket) }"
                      >
                        <i class="green ticket-check-area"></i>
                      </label>
                    </span>
                  </div>
                </div>
                <div
                  class="ticket-button min-w-0 flex-1 p-3"
                  style="min-height: 80px"
                  :class="{
                    'active text-black': isSelected(ticket),
                    'opacity-50': isTransferredTeamTicket(ticket),
                  }"
                >
                  <div data-hj-suppress class="flex flex-1 items-center">
                    <div
                      data-hj-suppress
                      class="flex-1 truncate pr-2 leading-normal"
                      :class="{
                        _600:
                          ticket.latest_message != null &&
                          ticket.latest_message.type == 'INBOUND' &&
                          ticket.status != 'CLOSED',
                      }"
                    >
                      {{ ticket.contact.full_name }}
                      <span v-if="!ticket.contact.full_name && ticket.contact.email">{{ ticket.contact.email }}</span>
                      <i v-show="isTransferredTeamTicket(ticket)" class="fa fa-group text-muted text-xs"></i>
                    </div>
                    <div class="flex items-center">
                      <i
                        v-if="
                          ticket.latest_message &&
                          ticket.latest_message.attachments &&
                          ticket.latest_message.attachments.length > 0
                        "
                        class="material-icons text-md mb-0 mr-2 pb-0 text-grey-500"
                      >
                        attachment
                      </i>
                      <div class="ml-auto text-xs text-grey-500">
                        {{ ticket.messages_count }}
                      </div>
                    </div>
                  </div>
                  <div class="flex min-w-0 items-center leading-normal">
                    <div class="flex min-w-0 flex-1 items-start truncate text-sm">
                      <div class="text-ellipsis">
                        <div v-if="ticket.channel.type === 'EMAIL'" data-hj-suppress class="leading-normal">
                          <div class="mr-2 truncate text-sm">
                            <i v-if="channelIsPrivate(ticket.channel.id)" class="fa fa-lock text-grey-600"></i>
                            {{ ticket.subject }}
                          </div>
                        </div>
                        <div
                          v-if="ticket.latest_message"
                          data-hj-suppress
                          class="flex items-center truncate text-grey-600"
                        >
                          <span class="flex-1 truncate">
                            {{ stripHtml(ticket.latest_message.message) }}
                          </span>
                        </div>
                      </div>
                      <div
                        v-if="
                          ticket.latest_message != null &&
                          ticket.latest_message.type == 'INBOUND' &&
                          ticket.status != 'CLOSED'
                        "
                        data-hj-suppress
                        class="success label ml-1 ml-auto flex-shrink-0 rounded"
                        style="height: 9px; width: 9px; padding: 0; margin-top: 4px"
                      ></div>
                    </div>
                  </div>
                  <div data-hj-suppress class="flex flex-nowrap text-xs leading-normal text-grey-500">
                    <div class="_400 mr-2 flex-none text-xs text-grey-500">
                      <datetime
                        v-if="ticket.latest_message"
                        :suffix="false"
                        :allow-change="false"
                        :time="ticket.latest_message.created_at"
                        :pretty="$root.prettyDates"
                      ></datetime>
                      <datetime
                        v-else
                        :allow-change="false"
                        :suffix="false"
                        :time="ticket.created_at"
                        :pretty="$root.prettyDates"
                      ></datetime>
                    </div>
                    <div class="_400 ml-auto flex min-w-0 items-center pl-4 text-grey-500">
                      <span class="mr-1 text-ellipsis" :style="{ color: (ticket.channel || {}).color }">
                        {{ (ticket.channel || {}).title }}
                      </span>
                      <channel-icon
                        class="text-sm"
                        :channel="ticket.channel.type"
                        :class="{
                          'text-orange': isSearching && ticket.status === 'OPEN',
                          'text-green': isSearching && ticket.status === 'ASSIGNED',
                        }"
                      ></channel-icon>
                    </div>
                  </div>

                  <div v-show="ticket.labels !== null && ticket.labels.length > 0" class="">
                    <span
                      v-for="label in ticket.labels"
                      :key="label.name"
                      data-hj-suppress
                      class="label default mb-0 mr-1 leading-none"
                      :style="{ background: 'white', border: '1px solid ' + label.color, color: label.color }"
                      style="line-height: 10px"
                    >
                      {{ label.name }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <infinite-loading
              direction="bottom"
              :identifier="infiniteId"
              force-use-infinite-wrapper="true"
              @infinite="fetchData"
            >
              <template #spinner>
                <div></div>
              </template>
              <template #no-more>
                <div></div>
              </template>
              <template #no-results>
                <div></div>
              </template>
              <template #error><span @click="fetchData">- Try again -</span></template>
            </infinite-loading>
          </div>
        </div>
      </div>
      <router-link
        v-if="
          $root.companyProfile.subscription.trial_ends_at_days > 0 &&
          userStore.hasPermission(PERMISSION.SETTINGS__COMPANY_PROFILE__MANAGE)
        "
        style="font-size: 14px"
        :to="SETTINGS_URL.ADMIN__SUBSCRIPTION_SETTINGS"
        class="fle mx-4 mt-auto py-4 text-center text-grey-500"
      >
        <span
          v-html="
            $t('tickets.trial', { days: $root.companyProfile.subscription.trial_ends_at_days }) +
            ' ' +
            $t(
              $root.companyProfile.subscription.trial_ends_at_days === 1
                ? 'tickets.trial_days_single'
                : 'tickets.trial_days_plural'
            ) +
            '. ' +
            $t('tickets.trial_subscribe')
          "
        ></span>
      </router-link>
    </div>
    <div
      :class="{ 'w-2': isDraggingTicketSidebar }"
      class="z-50 h-full w-[1px] cursor-col-resize bg-grey-300 hover:w-[2px] hover:bg-grey-400"
      @mousedown="startDrag"
    />
    <router-view :key="$route.path" class="view"></router-view>
    <forward-ticket-modal></forward-ticket-modal>
    <ticket-result-modal></ticket-result-modal>
    <contact-profile-modal></contact-profile-modal>
    <merge-ticket-modal></merge-ticket-modal>
    <div id="dragoverlay" class="image_drop_overlay">
      {{ $t('tickets.drag_file_to_send') }}
    </div>
  </div>
</template>
<style scoped lang="scss">
.no-select {
  user-select: none;
}

.tt-search-box {
  border-radius: 10px;

  input {
    width: 100%;
    background-color: #f1f1f1;
    padding: 9px 10px 10px 5px;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
    opacity: 1;
    color: #adadad;
    border: none;

    &::-ms-clear {
      display: none;
    }
  }

  .input-group-addon {
    border: none !important;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
    color: #adadad;
    padding-right: 0.75rem;
    background-color: #f1f1f1;
  }

  .remove-addon {
    border: none !important;
  }
}
</style>

import { formatWithOptions } from 'date-fns/fp';
import en from 'date-fns/locale/en-GB';

import type { BillingFrequency, PlanId } from '@/types';

const PLAN_NAME = {
  tier1: 'Grow',
  tier2: 'Scale',
  tier3: 'Enterprise',
};

const BILLING_FREQUENCY = {
  monthly: 'Monthly',
  annually: 'Annually',
};

export const getPlanName = (planId: PlanId) => PLAN_NAME[planId];

export const getBillingFrequencyName = (billingFrequency: BillingFrequency) => BILLING_FREQUENCY[billingFrequency];

export const dateToString = formatWithOptions({ locale: en }, 'MMMM d, yyyy');

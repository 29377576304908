<script lang="ts" setup>
import AccountPagePanel from '@/components/common/AccountPagePanel/Index.vue';
import { SETTINGS_URL } from '@/Configs/Constants';
import { useSubscriptionChangesStore } from '@/store/pinia';

import CarrierCostsSection from './EditSubscription/CarrierCosts/CarrierCostsSection.vue';
import ConversationBlocksSection from './EditSubscription/Conversations/ConversationBlocksSection.vue';

useSubscriptionChangesStore().init();
</script>

<template>
  <account-page-panel
    :title="$t('edit_subscription.title')"
    :has-back-button="true"
    @back-click="$router.push(SETTINGS_URL.ADMIN__SUBSCRIPTION_SETTINGS)"
  >
    <div class="w-full rounded-xl border-1 border-grey-300 bg-white">
      <conversation-blocks-section />
      <t-divider />
      <carrier-costs-section />
    </div>
  </account-page-panel>
</template>

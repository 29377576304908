<script lang="ts" setup>
import { WalletPlusLinear, ChevronDownLinear, ArrowTopCircleLinear, ArrowTopRightLinear } from '@trengo/trengo-icons';
import { computed, ref } from 'vue';

import { formatPrice } from '@/util/currency';

import { getCarrierCostOptions } from '../../../utils';

type CarrierCostsEditorProps = {
  carrierCosts: number;
  error?: string;
  disabled?: boolean;
  isSubmitted?: boolean;
  currentCarrierCosts?: number;
  scheduledCarrierCosts?: number;
};

const props = defineProps<CarrierCostsEditorProps>();
const emit = defineEmits(['edit', 'change']);
const carrierCosts = ref(props.carrierCosts);
const MAX_CARRIER_COST_QUANTITY = 40;
const MULTIPLIER_EURO_25 = 2500;

const showContactSupportBanner = computed(() => carrierCosts.value >= MAX_CARRIER_COST_QUANTITY);

const handleChange = (costs: number) => {
  carrierCosts.value = costs;
  emit('change', costs);
};

const hasScheduledChanges = computed(() => props.scheduledCarrierCosts !== undefined);
</script>

<template>
  <div>
    <div class="flex w-full items-center gap-3 rounded-xl bg-grey-200 p-2 text-grey-800">
      <t-thumbnail class="bg-white">
        <wallet-plus-linear size="1rem" />
      </t-thumbnail>
      <div class="flex-grow">
        <div class="t-text-sm flex items-center gap-2 font-semibold">
          {{ $t(`edit_subscription.carrier_costs_input_title`) }}
        </div>
      </div>
      <div v-if="!disabled" class="w-48">
        <t-select-input
          :default-value="carrierCosts"
          :options="getCarrierCostOptions(props.currentCarrierCosts)"
          class="w-48"
          @change="handleChange"
        />
      </div>
      <div
        v-if="disabled"
        class="border-gray-400 t-text-sm flex h-9 w-48 items-center justify-between gap-6 rounded-md border px-2"
      >
        <div>
          <span v-if="hasScheduledChanges && !isSubmitted" class="mr-1 text-sky-600">
            {{ formatPrice(props.scheduledCarrierCosts!) }}
          </span>
          <span class="text-grey-500" :class="{ 'line-through': hasScheduledChanges || isSubmitted }">
            {{ formatPrice(props.carrierCosts * MULTIPLIER_EURO_25) }}
          </span>
        </div>
        <chevron-down-linear size="1.3rem" class="text-grey-500" />
      </div>
      <t-button v-if="disabled" :disabled="isSubmitted" btn-style="secondary" @click="emit('edit')">
        {{ $t('general.edit') }}
      </t-button>
    </div>
    <t-inline-banner v-if="showContactSupportBanner" class="mt-3" type="upgrade">
      <template #icon>
        <arrow-top-circle-linear />
      </template>
      <div class="t-text-sm text-grey-800">
        {{ $t('edit_subscription.carrier_costs_contact_support_description') }}
      </div>
      <template #action>
        <t-button size="sm" btn-style="secondary" href="https://trengo.com/contact" target="_blank">
          {{ $t('general.contact_support') }}
          <arrow-top-right-linear size="1.125rem" class="ml-1 inline" />
        </t-button>
      </template>
    </t-inline-banner>
    <t-error-item v-if="error" class="ml-4" danger-icon :text="error" />
  </div>
</template>

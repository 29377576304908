import { computed } from 'vue';

import { useSubscriptionStore } from '@/store/pinia';

export const useConversationBlocks = () => {
  const subscriptionStore = useSubscriptionStore();

  type ConversationDetails = {
    prices: {
      monthly: number;
      annually: number;
    };
    includedConversations?: {
      tier0: number;
      tier1: number;
      tier2: number;
      tier3: number;
    };
  };

  const DETAILS: ConversationDetails = {
    prices: {
      monthly: 18,
      annually: 15,
    },
    includedConversations: {
      tier0: 0,
      tier1: 100,
      tier2: 300,
      tier3: 1000,
    },
  };

  const billingFrequency = computed(() => subscriptionStore.subscription.subscriptionFrequency);
  const plan = computed(() => subscriptionStore.subscription.tierId);
  const unitPrice = computed(() => (billingFrequency.value ? DETAILS.prices[billingFrequency.value] * 100 : 0));

  const includedConversations = computed(() => {
    if (!plan.value || !billingFrequency.value) {
      return 0;
    }

    const conversations = DETAILS.includedConversations?.[plan.value] ?? 0;

    return billingFrequency.value === 'monthly' ? conversations : conversations * 12;
  });

  return { billingFrequency, includedConversations, unitPrice };
};

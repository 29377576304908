import { defineStore } from 'pinia';
import { ref } from 'vue';

import { API_INBOX_AI } from '@/components/ReplyForm/api/const';
import { request } from '@/util';

import { getIsStorageTranslationAvailable } from './getIsStorageTranslationAvailable';

import type { FetchStatus, Translation } from './types';
import type { IsTranslationAvailableResponse } from '@/components/ReplyForm/TranslationOption/types';
import type { UserLocales } from '@/components/ReplyForm/TranslationOption/useLocales';

export const useInboxTranslationStore = defineStore('inboxTranslation', () => {
  const status = ref<FetchStatus>('INITIAL');
  const locale = ref<UserLocales>('EN_GB');
  const isActive = ref(false);

  const setChannelSupportStatus = async (newTicketId: number | string) => {
    const { data } = await axios.get<IsTranslationAvailableResponse>(
      `${API_INBOX_AI}/support_translation?ticket_id=${newTicketId}`
    );
    isActive.value = !!data?.is_translation_supported;
  };

  const fetchTranslation = async (payload: Translation) => {
    if (!payload.ticket_id) throw new Error('Ticket ID is required');
    if (!isActive.value) return;

    status.value = 'LOADING';
    try {
      const response = await request<{ original_prompt: string; translated_prompt: string }>(
        API_INBOX_AI + '/translation',
        'POST',
        payload
      );

      status.value = 'SUCCESS';

      const item = sessionStorage.getItem('inbound_translations');
      const items = item ? JSON.parse(item) : [];
      const newTranslation = {
        id: `${payload.ticket_id}_${payload.message_id}_${payload.locale}_translated`,
        original: response.data.original_prompt,
        translated: response.data.translated_prompt,
      };

      const exists = items.some((translation: { id: string }) => translation.id === newTranslation.id);

      if (!exists) {
        items.push(newTranslation);
        sessionStorage.setItem('inbound_translations', JSON.stringify(items));
      }

      return response.data;
    } catch {
      status.value = 'FAILED';
    } finally {
      status.value = 'INITIAL';
    }
  };

  return {
    status,
    locale,
    isActive,
    getIsStorageTranslationAvailable,
    setChannelSupportStatus,
    fetchTranslation,
  };
});

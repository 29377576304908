import _ from 'lodash';
import { h } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';

import { generateDangerZoneToken } from '@/api';
import { CHANNEL_TYPE } from '@/Configs/Constants';
import { LOCAL_STORAGE } from '@/Configs/Constants/localStorage';
import { MIDDLEWARE_ERROR } from '@/Configs/Constants/middlewareError';
import { AUTH_URL } from '@/Configs/Constants/navigation/authUrl';
import { checkAuthentication } from '@/middleware/auth';
import { loadEntitlements } from '@/middleware/entitlements';
import { loadFeatureFlags } from '@/middleware/featureFlags';
import { checkPermission } from '@/middleware/permission';
import { checkPricingModel } from '@/middleware/pricingModel';
import { useUserStore } from '@/store/pinia';
import { useRoutesStore } from '@/store/pinia/routes';
import { trackPageWithSegmentAnalytics, trackSettingsPageWithSegment } from '@/util/analytics/segmentAnalytics';
import { flashError } from '@/util/flashNotification';

import admin from './admin/admin';
import dashboard from './dashboard';
import store from '../store/index';

const EmptyComponent = { render: () => h('div') };

let routes = [
  {
    path: AUTH_URL.LOGIN,
    name: 'login',
    props: true,
    component: () => import(/* webpackChunkName: "Login" */ '../components/User/Login'),
    meta: { allowUnauthenticated: true },
  },
  {
    path: '/reset-password/request-email',
    name: 'request-email',
    props: true,
    component: require('../components/User/RequestEmail').default,
    meta: { allowUnauthenticated: true },
  },
  {
    path: '/reset-password/reset-password/:token',
    name: 'reset-password',
    component: require('../components/User/ResetPassword').default,
    meta: { allowUnauthenticated: true },
  },
  {
    path: AUTH_URL.PASSWORD,
    name: 'auth-password',
    component: require('../components/User/SetupPassword').default,
  },
  {
    path: AUTH_URL.SETUP_2FA,
    name: 'setup-2fa',
    component: require('../components/User/SetupTwoFactorAuth').default,
  },
  {
    path: '/composer/:ticketId?',
    component: require('../components/ComposerPopout').default,
  },
  {
    path: '/',
    component: require('../components/Dashboard').default,
    children: [...dashboard, ...admin],
  },
  {
    path: '/verification',
    beforeEnter: async (to, _from) => {
      if (!to.query.secret) {
        console.error('No secret provided for verification');
        return '/';
      }
      try {
        const {
          data: { token },
        } = await generateDangerZoneToken(to.query.secret);
        localStorage.setItem(LOCAL_STORAGE.DANGER_ZONE_TOKEN, token);
      } catch (error) {
        console.error(error);
        flashError(error?.response?.data?.message || error?.message || 'An error occurred');
      }
      return '/';
    },
    component: EmptyComponent, // necessary for beforeEnter to work
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  linkActiveClass: 'active',
});

router.beforeEach(async (to, from) => {
  const runSimultaneously = (middlewares) => (to, from) =>
    Promise.all(middlewares.map((middleware) => middleware(to, from)));
  // FIXME: set a global flag in a separate Pinia module to indicate whether the data is still loading,
  // show a spinner while the middlewares are being resolved
  // https://linear.app/trengo/issue/ENG-1/[fe]-implement-isapploading-store-to-signal-to-the-users-that
  const middlewares = [
    checkAuthentication,
    loadFeatureFlags,
    loadEntitlements,
    runSimultaneously([checkPermission, checkPricingModel]),
  ]; // order matters! (middlewares may have dependencies on one another)
  for (const middleware of middlewares) {
    try {
      // .call(...) might seem redundant, but it's necessary to comply with Vue Router's type definitions
      // https://github.com/Microsoft/TypeScript/issues/31123#issuecomment-570737034
      await middleware.call(this, to, from);
    } catch (error) {
      switch (error?.message) {
        case MIDDLEWARE_ERROR.PERMISSION_REQUIRED:
        case MIDDLEWARE_ERROR.PRICING_MODEL_MISMATCH:
          return { name: 'unauthorized' };
        case MIDDLEWARE_ERROR.AUTHENTICATION_REQUIRED:
        default:
          return { name: 'login' };
      }
    }
  }
  const user = store.getters['initialData/user'];
  const shouldRedirectToMFASetup = store.getters['initialData/shouldRedirectToMFASetup'];
  if (user?.needs_new_pw && to.path !== AUTH_URL.PASSWORD) {
    return AUTH_URL.PASSWORD;
  }
  if (shouldRedirectToMFASetup && to.path !== AUTH_URL.SETUP_2FA) {
    return AUTH_URL.SETUP_2FA;
  }

  const deprecatedChannels = [CHANNEL_TYPE.TWITTER, CHANNEL_TYPE.WECHAT];
  const isChannelDeprecated = deprecatedChannels.some(
    (channel) => channel.toLowerCase() === to.params.channel?.toLowerCase()
  );

  if (isChannelDeprecated) {
    return '/';
  }

  return true;
});

router.afterEach((to, from) => {
  const userStore = useUserStore();
  const user = userStore.user;
  const fullPath = to.fullPath;
  const isAdminRoute = to.meta?.admin;
  trackPageWithSegmentAnalytics({ user, route: fullPath });

  const routesStore = useRoutesStore();
  routesStore.setLastRoutePath(from.path);
  routesStore.setCurrentRoutePath(to.path);

  if (isAdminRoute) {
    routesStore.setIsAdminRoute(to.meta?.admin);
    routesStore.setActiveSettingsBaseRoutePath();
    routesStore.setActiveSettingsNavigationFeatureName();
    routesStore.setActiveSettingsNavigationGroupName();
    trackSettingsPageWithSegment({
      isSettingsPage: to.meta?.admin,
      pageUrl: to.path,
      agencyId: user?.agency_id,
      userId: user?.id,
      category: routesStore?.activeSettingsNavigationGroupName,
      featureName: routesStore?.activeSettingsFeatureName,
    });
  }

  ga('set', 'page', to.path);
  ga('send', 'pageview');

  if (typeof Appcues !== 'undefined') {
    setTimeout(() => Appcues.page(), 1000);
  }

  // Remove modal backdrop
  $('.modal-backdrop').remove();
});

export default router;

import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

import { FEATURE_FLAG_SUBSCRIPTION, PERMISSION } from '@/Configs/Constants';
import { useCompanyProfileStore, useFeatureFlagStore, useUserStore } from '@/store/pinia';

import { fetchChannels, fetchNotifications, putDismissNotification } from './api';
import { IS_DISMISSED_KEY, MIGRATION_CUSTOM_NOTIFICATION } from './constants';
import { checkMigrationBannerVisibility } from './utils';

import type { ServiceNotification } from './types';
import type { ChannelType } from '@/types';

export const useServiceNotificationsStore = defineStore('serviceNotifications', () => {
  const status = ref<'pending' | 'initialized' | 'error'>('pending');
  const channels = ref<ChannelType[]>([]);
  const notifications = ref<ServiceNotification[]>([]);

  const hasAgencyMigrationBanner = computed(() =>
    checkMigrationBannerVisibility({
      pricingModel: useCompanyProfileStore().pricingModel,
      alreadyDismissed: Boolean(localStorage.getItem(IS_DISMISSED_KEY)),
      isAdmin: useUserStore().hasPermission(PERMISSION.SETTINGS__COMPANY_PROFILE__MANAGE),
      hasFeatureFlag: useFeatureFlagStore().isEnabled(FEATURE_FLAG_SUBSCRIPTION.TW_USAGE_BASED_MIGRATION_BANNER),
    })
  );

  const init = async () => {
    if (status.value === 'initialized') return;
    channels.value = await fetchChannels();
    await loadNotifications();
  };

  const loadNotifications = async () => {
    status.value = 'pending';
    try {
      const response = await fetchNotifications(channels.value);
      notifications.value = hasAgencyMigrationBanner.value ? [MIGRATION_CUSTOM_NOTIFICATION, ...response] : response;
      status.value = 'initialized';
    } catch (err) {
      console.error(err);
      status.value = 'error';
    }
  };

  const dismissNotification = async (ServiceNotification: ServiceNotification) => {
    notifications.value = notifications.value.filter((item) => item.id !== ServiceNotification.id);
    if (ServiceNotification.id === MIGRATION_CUSTOM_NOTIFICATION.id) {
      localStorage.setItem(IS_DISMISSED_KEY, 'true');
    } else {
      putDismissNotification(ServiceNotification.id);
    }
  };

  return {
    channels,
    init,
    notifications,
    status,
    dismissNotification,
    reload: loadNotifications,
  };
});

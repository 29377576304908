<script lang="ts" setup>
import {
  NewspaperLinear,
  CreditCardLinear,
  PinLinear,
  ExternalLinkLinear,
  ChevronRightLinear,
} from '@trengo/trengo-icons';
import { capitalize } from 'lodash';
import { storeToRefs } from 'pinia';
import { computed, onMounted } from 'vue';

import { openChargebeePortal } from '@/api/modules/subscriptionChargebee';
import { useChargebeeDuePayment } from '@/composables/useChargebeeDuePayment';
import { SUBSCRIPTION_STATUS } from '@/Configs/Constants';
import { SETTINGS_URL } from '@/Configs/Constants/navigation/settingsUrl';
import { useSubscriptionStore, useUserStore } from '@/store/pinia';

import ScheduledChangeBanner from './ScheduledChangeBanner.vue';
import { mapAddonsToCategoryBased } from '../utils';

import type { SubscriptionAddonUsageBased } from '@/types';

const { subscription } = storeToRefs(useSubscriptionStore());
const userStore = useUserStore();
const { subscriptionDuePrice, subscriptionDueDate } = useChargebeeDuePayment();

const brightAppId = window.BRIGHT_APP_ID;

const cbInstance = window.Chargebee.init({
  site: window.CHARGEBEE_SITE,
  publishableKey: window.CHARGEBEE_PUBLISHABLE_KEY,
});
const cbPortal = cbInstance.createChargebeePortal();

const includedFeatures = computed(() =>
  subscription.value.pricingModel === 'usage' ? subscription.value.includedItems : []
);
const subscriptionAddons = computed(() => {
  try {
    const addons = mapAddonsToCategoryBased(subscription.value.addons as SubscriptionAddonUsageBased[]);
    return addons;
  } catch (error) {
    return [];
  }
});
const isCancelScheduled = computed(
  () => subscription.value.subscriptionStatus === SUBSCRIPTION_STATUS.CANCEL_SCHEDULED
);

const initPortal = () => {
  cbInstance.setPortalSession(() => {
    return openChargebeePortal().then((response) => response.data);
  });
};

const editPaymentMethods = () => {
  cbPortal.openSection({
    sectionType: window.Chargebee.getPortalSections().PAYMENT_SOURCES,
  });
};

const showBillingHistory = () => {
  cbPortal.openSection({
    sectionType: window.Chargebee.getPortalSections().BILLING_HISTORY,
  });
};

const editBillingAdress = () => {
  cbPortal.openSection({
    sectionType: window.Chargebee.getPortalSections().ADDRESS,
  });
};

const cancelSubscription = () => {
  if (!window.Brightback) {
    return false;
  }
  window.Brightback.handleDataPromise({
    app_id: brightAppId,
    email: userStore.user?.email,
    subscription_id: subscription.value.subscriptionId,
    save_return_url: window.location.href,
    cancel_confirmation_url: window.location.href,
  }).then((result: any) => {
    if (result.valid) {
      window.location = result.url;
    }
  });
};

onMounted(() => {
  initPortal();
});
</script>

<template>
  <div>
    <scheduled-change-banner v-if="subscription.isScheduledToDowngrade">
      <template #heading>Downgrade scheduled</template>
      <template #subheading>
        Downgrade will take effect from the next billing cycle on {{ subscriptionDueDate }}
      </template>
    </scheduled-change-banner>
    <scheduled-change-banner v-if="isCancelScheduled" with-button>
      <template #heading>Your subscription is cancelled</template>
      <template #subheading>In order to re-activate your account, please contact Trengo support</template>
      <template #buttonLabel>Contact Support</template>
    </scheduled-change-banner>
    <div class="mb-6 rounded-xl border-1 border-grey-300 bg-white">
      <div class="p-6">
        <div class="flex items-center gap-4 pb-6">
          <t-thumbnail class="bg-white">
            <newspaper-linear size="1rem" />
          </t-thumbnail>
          <div class="flex-grow">
            <div class="t-text-h5 font-bold text-grey-800">
              You are on the {{ subscription.subscriptionName }} plan
              <span class="t-text-xs font-semibold text-grey-600">
                ({{ capitalize(subscription.subscriptionFrequency) }})
              </span>
            </div>
            <div class="t-text-sm text-grey-700">Thank you for using Trengo</div>
          </div>
        </div>
        <div class="xs:t-text-sm xl:t-text-md grid grid-cols-2 gap-4">
          <div
            v-for="(item, index) in includedFeatures"
            :key="index"
            class="flex items-center gap-3 rounded-xl bg-grey-200 p-3 text-grey-800"
          >
            <t-thumbnail class="bg-white" size="small">
              <component :is="item.icon"></component>
            </t-thumbnail>
            <div class="flex-grow">{{ item.title }}</div>
            <t-badge variant="default" text=".incl" />
          </div>
        </div>
      </div>
      <div class="border-t-1 border-grey-300" />
      <div v-for="(addon, addonIndex) in subscriptionAddons" :key="addonIndex">
        <div class="p-6">
          <h5 class="t-text-h5 pb-1 text-grey-800">{{ addon.title }}</h5>
          <div class="xs:t-text-sm xl:t-text-md grid grid-cols-2 gap-4">
            <div
              v-for="(item, index) in addon.items"
              :key="index"
              class="flex items-center gap-3 rounded-xl bg-grey-200 p-3 text-grey-800"
            >
              <t-thumbnail class="bg-white" size="small">
                <component :is="item.icon"></component>
              </t-thumbnail>
              {{ item.title }}
            </div>
          </div>
        </div>
        <div class="border-t-1 border-grey-300" />
      </div>

      <div class="flex flex-col gap-4 p-6">
        <div class="t-text-h5 font-semibold text-grey-800">
          Billing
          <div v-if="!isCancelScheduled" class="t-text-sm font-normal text-grey-600">
            Your next {{ subscription.subscriptionFrequency }} payment of
            <span class="text-grey-800">
              {{ subscriptionDuePrice }} is due
              <span v-if="subscriptionDueDate">on {{ subscriptionDueDate }}.</span>
            </span>
          </div>
        </div>
        <div class="flex gap-4 text-grey-800">
          <button
            class="
              t-text-sm
              flex
              w-[400px]
              items-center
              gap-2
              rounded-xl
              border-1 border-grey-300
              bg-grey-100
              p-3
              hover:bg-grey-200
              active:bg-grey-300
            "
            @click="editPaymentMethods"
          >
            <t-thumbnail class="bg-white" size="tiny">
              <credit-card-linear size="1rem" />
            </t-thumbnail>

            <div>Payment details</div>
            <div class="ml-auto">
              <external-link-linear size="20px" />
            </div>
          </button>
          <button
            class="
              t-text-sm
              flex
              w-[400px]
              items-center
              gap-2
              rounded-xl
              border-1 border-grey-300
              bg-grey-100
              p-3
              hover:bg-grey-200
              active:bg-grey-300
            "
            @click="editBillingAdress"
          >
            <t-thumbnail class="bg-white" size="tiny">
              <pin-linear />
            </t-thumbnail>
            <span>Billing address</span>
            <div class="ml-auto">
              <external-link-linear size="20px" />
            </div>
          </button>
        </div>
      </div>
      <div class="border-t-1 border-grey-300" />
      <div class="flex justify-between gap-2 p-6">
        <t-button v-if="!isCancelScheduled" id="bb-cancel" size="md" btn-style="danger-alt" @click="cancelSubscription">
          Cancel subscription
        </t-button>
        <div class="flex justify-end gap-3">
          <t-button size="md" btn-style="secondary" @click="showBillingHistory">Billing history</t-button>
          <t-button
            v-if="!isCancelScheduled"
            size="md"
            btn-style="primary"
            class="flex items-center"
            @click="$router.push(`${SETTINGS_URL.ADMIN__SUBSCRIPTION_SETTINGS}/${subscription.subscriptionId}`)"
          >
            Edit subscription
            <chevron-right-linear size="1.125rem" class="ml-1 inline" />
          </t-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import { addAddonsWithQuantity, updateAddonsWithQuantity } from '@/api';
import { SUBSCRIPTION_ADDON_CHARGEBEE_TYPE } from '@/Configs/Constants';
import { useSubscriptionChangesStore, useSubscriptionStore } from '@/store/pinia';
import { useToastMessageStore } from '@/store/pinia/toastMessage';

import ConversationBlocksEditor from './ConversationBlocksEditor.vue';
import EditConversationBlocksModal from './EditConversationBlocksModal.vue';
import { useConversationBlocks } from './useConversationBlocks';
import ScheduledChangePanel from '../ScheduledChangePanel.vue';
import ScheduledChangeWarningModal from '../ScheduledChangeWarningModal.vue';

import type { BillingFrequency } from '@/types';

const { t } = useI18n();
const subscriptionStore = useSubscriptionStore();
const modalStatus = ref<'editModal' | 'warningModal' | 'none'>('none');
const newConversationBlocks = ref();
const isSubmitted = ref(false);

const conversationBlocks = computed(() =>
  !subscriptionStore.subscription.isLoading
    ? subscriptionStore.getUsageBasedAddonQuantity(SUBSCRIPTION_ADDON_CHARGEBEE_TYPE.CONVERSATION)
    : 0
);

const CONVERSATION_QUANTITY: Record<BillingFrequency, number> = {
  monthly: 100,
  annually: 1200,
};

const { scheduledChangeDate, getScheduledChangeForAddon } = storeToRefs(useSubscriptionChangesStore());

const scheduledChange = computed(() =>
  getScheduledChangeForAddon.value(SUBSCRIPTION_ADDON_CHARGEBEE_TYPE.CONVERSATION)
);

const { includedConversations } = useConversationBlocks();

const totalConversations = computed(() =>
  subscriptionStore.subscription.subscriptionFrequency
    ? conversationBlocks.value * CONVERSATION_QUANTITY[subscriptionStore.subscription.subscriptionFrequency] +
      includedConversations.value
    : 0
);

const closeModal = () => (modalStatus.value = 'none');

const handleEditModalOpen = () => {
  modalStatus.value = 'editModal';
};

const updateType = computed(() => {
  switch (true) {
    case conversationBlocks.value === 0 && newConversationBlocks.value > 0:
      return 'addon_added';
    case newConversationBlocks.value > conversationBlocks.value:
      return 'addon_scheduled_to_be_added';
    case newConversationBlocks.value < conversationBlocks.value:
      return 'addon_scheduled_to_be_removed';
    case newConversationBlocks.value === conversationBlocks.value:
      return 'addon_scheduled_change_removed';
    default:
      return undefined;
  }
});

const showToast = () => {
  if (!updateType.value) {
    return;
  }

  const toastMessageStore = useToastMessageStore();
  toastMessageStore.$patch({
    hasCloseIcon: true,
    text: t(`edit_subscription.${updateType.value}`, { addon: t('edit_subscription.conversation_blocks') }),
    showToast: true,
  });
  toastMessageStore.hideToastMessage();
};

const handleSave = (newValue: number) => {
  newConversationBlocks.value = newValue;
  if (getScheduledChangeForAddon.value(SUBSCRIPTION_ADDON_CHARGEBEE_TYPE.CONVERSATION)) {
    modalStatus.value = 'warningModal';
  } else {
    handleSubmit();
  }
};

const handleSubmit = async () => {
  modalStatus.value = 'none';
  const isAdding = conversationBlocks.value === 0 && newConversationBlocks.value > 0;
  isAdding
    ? await addAddonsWithQuantity({ standard_conversation_blocks: newConversationBlocks.value })
    : await updateAddonsWithQuantity({ standard_conversation_blocks: newConversationBlocks.value });

  isSubmitted.value = true;

  showToast();
  closeModal();

  if (isAdding) {
    subscriptionStore.reload();
  }
};
</script>

<template>
  <div class="p-6">
    <h3 class="t-text-md-emphasize mb-2 text-grey-800">
      {{ $t('edit_subscription.conversation_blocks_title') }}
    </h3>
    <p class="t-text-sm text-grey-600">{{ $t('edit_subscription.conversation_blocks_description') }}</p>
    <div>
      <div class="flex flex-col gap-3">
        <conversation-blocks-editor
          :blocks="conversationBlocks"
          :scheduled-blocks="scheduledChange"
          disabled
          :is-submitted="isSubmitted"
          @edit="handleEditModalOpen"
        />
      </div>

      <scheduled-change-panel
        :is-submitted="isSubmitted"
        :has-scheduled-change="scheduledChange !== undefined"
        :scheduled-change-date="scheduledChangeDate"
      >
        <template #right>
          <div class="mt-2 w-full text-right">
            <strong class="t-text-sm-emphasize">{{ $t('edit_subscription.total') }}</strong>
            <span class="t-text-sm text-grey-600">
              {{
                `${totalConversations} ${$t(`edit_subscription.conversation_blocks_standard`)} ${$t(
                  `edit_subscription.conversation_blocks_total_per_${subscriptionStore.subscription.subscriptionFrequency}`
                )}`
              }}
            </span>
          </div>
        </template>
      </scheduled-change-panel>
    </div>
    <edit-conversation-blocks-modal
      v-if="modalStatus === 'editModal'"
      :blocks="conversationBlocks"
      @save="handleSave"
      @close="closeModal"
    />
    <scheduled-change-warning-modal
      v-if="scheduledChangeDate && modalStatus === 'warningModal'"
      :scheduled-change-date="scheduledChangeDate"
      @continue="handleSubmit"
      @close="closeModal"
    />
  </div>
</template>

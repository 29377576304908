<template>
  <div>
    <settings-landing :title="title" :icon="icon" :badge="computedBadge" :list="list">
      <template v-if="isAddOnBadgeVisible" #entitlement-badge>
        <t-badge
          :text="$t('entitlement.requires_add_on')"
          variant="upgrade"
          class="w-fit cursor-default"
          data-test="entitlement-badge"
        >
          <template #icon><arrow-top-circle-linear size="1rem" /></template>
        </t-badge>
      </template>
      <t-button
        v-if="isConnectButtonVisible"
        size="lg"
        aria-label="Connect Voice"
        type="button"
        label="Button"
        class="mb-4 mr-0 xl:mb-0 xl:mr-4"
        @click.prevent="showCreateModal(true)"
      >
        {{ $t('voice.connect_voice') }}
      </t-button>
      <t-button
        v-if="isUnlockButtonVisible"
        size="lg"
        aria-label="Unlock Voice"
        type="button"
        label="Button"
        class="mb-4 mr-0 xl:mb-0 xl:mr-4"
        @click="$router.push(SETTINGS_URL.ADMIN__SUBSCRIPTION_SETTINGS)"
      >
        <div class="flex items-center justify-center">
          <arrow-top-circle-linear class="mr-1" size="1.25rem" />
          {{ $t('entitlement.unlock_feature', { featureName: ADDON_NAME.VOICE }) }}
        </div>
      </t-button>
      <t-button
        size="lg"
        btn-style="secondary"
        aria-label="Test Voice"
        type="button"
        label="Button"
        @click="showSandboxModal()"
      >
        {{ $t('voice.test_voice') }}
      </t-button>
      <template v-if="isHintVisible" #hint>
        <p class="mt-2.5 text-sm">
          {{ $t('entitlement.admin_unlock_feature', { featureName: ADDON_NAME.VOICE }) }}
        </p>
      </template>
    </settings-landing>
    <div class="container flex flex-col items-center justify-center pt-8 leading-none lg:pt-32">
      <div ref="modal" class="modal animated fadeIn voip-sandbox-modal" role="dialog" style="animation-duration: 0.3s">
        <div
          class="modal-centered modal-dialog"
          style="
            height: 90vh;
            overflow-y: auto;
            transform: translate(-50%, -50%);
            margin: 0;
            max-width: 1125px;
            width: 100vw;
          "
        >
          <div
            class="flex h-full flex-shrink-0 select-none flex-col overflow-hidden bg-white lg:flex-row lg:rounded-xl"
          >
            <button
              type="button"
              class="modal-close-btn leading-none"
              style="z-index: 99999"
              data-dismiss="modal"
              aria-label="Close"
            >
              <i class="material-icons text-grey-500" style="margin-top: -3px">close</i>
            </button>
            <div
              class="
                phone-col
                mt-4
                flex
                h-full
                min-w-0
                flex-col
                items-center
                bg-green
                lg:mt-0 lg:flex-shrink-0 lg:justify-center
              "
            >
              <div v-if="!$root.mobileDevice" class="phone-title mx-8 mb-4 mt-4 lg:mb-0 lg:mt-8">
                <div v-if="step === 2" class="animated bounceIn text-center font-bold leading-tight text-white">
                  {{ $t('voice.waiting_for_your_call') }}
                </div>
                <div v-else-if="step === 3" class="animated bounceIn text-center font-bold leading-tight text-white">
                  {{ $t('voice.pick_up_the_phone') }}
                </div>
                <div v-else-if="step === 4" class="animated bounceIn text-center font-bold leading-tight text-white">
                  {{ $t('voice.connected') }}
                </div>
                <div v-else-if="step === 5" class="animated bounceIn text-center font-bold leading-tight text-white">
                  {{ $t('voice.call_ended') }}
                </div>
              </div>
              <div class="phone-container absolute flex w-full flex-col items-center lg:static lg:h-full">
                <div v-if="$root.mobileDevice" class="phone-title flex items-center">
                  <div v-if="step === 2" class="animated bounceIn text-center font-bold leading-tight text-white">
                    {{ $t('voice.waiting_for_your_call') }}
                  </div>
                  <div v-else-if="step === 3" class="animated bounceIn text-center font-bold leading-tight text-white">
                    {{ $t('voice.pick_up_the_phone') }}
                  </div>
                  <div v-else-if="step === 4" class="animated bounceIn text-center font-bold leading-tight text-white">
                    {{ $t('voice.connected') }}
                  </div>
                  <div v-else-if="step === 5" class="animated bounceIn text-center font-bold leading-tight text-white">
                    {{ $t('voice.call_ended') }}
                  </div>
                </div>
                <div
                  class="
                    phone-wrapper
                    absolute
                    h-full
                    overflow-hidden
                    rounded-xl
                    bg-white
                    px-2
                    pt-2
                    lg:static lg:w-4/5 lg:px-4 lg:pt-6
                  "
                >
                  <div
                    class="relative flex h-full w-full flex-col items-center rounded-xl bg-black"
                    style="border-bottom-left-radius: 0; border-bottom-right-radius: 0"
                  >
                    <div class="w-100 mt-2 flex justify-between px-4" style="min-height: 21px">
                      <div class="leading-normal text-white" style="font-size: 14px">{{ presentTime }}</div>
                      <div class="flex items-center">
                        <i class="material-icons md-18 mr-1 text-white">bluetooth</i>
                        <i class="material-icons md-18 mr-1 text-white">wifi</i>
                        <span class="mr-1 text-white" style="font-size: 14px">{{ $t('voice.72_percent') }}</span>
                        <i class="material-icons md-18 text-white">battery_charging_full</i>
                      </div>
                    </div>
                    <div class="flex h-full flex-col items-center justify-around">
                      <div
                        class="phone-wrapper-inner flex w-full flex-col items-center"
                        :class="{ 'lg:mb-16': !$root.mobileDevice && step !== 4 }"
                      >
                        <div class="flex flex-col items-center">
                          <div v-if="step === 3" class="mb-6 text-white">
                            {{ $t('voice.incoming_call_at') }}
                            <strong>{{ twilioNumber }}</strong>
                          </div>
                          <div v-else-if="step === 4" class="phone-counter text-white lg:mt-4">
                            <counter :to-format="'MMSS'"></counter>
                          </div>

                          <div class="phone-bg flex flex-col items-center bg-grey-600">
                            <span class="phone-bg-face flex"></span>
                            <span class="phone-bg-body flex"></span>
                          </div>
                          <div
                            v-if="step === 3 || step === 4"
                            class="phone-number mt-3 font-bold text-white lg:mt-1 lg:mt-6"
                          >
                            {{ phone }}
                          </div>
                        </div>
                        <div v-if="[1, 2, 5].includes(step)" ref="msgContainer" class="phone-description p-4">
                          <div
                            v-if="!messages.length"
                            class="mx-6 rounded-3xl bg-grey-600 p-4 leading-normal text-white shadow"
                          >
                            <span v-if="step === 1">
                              {{ $t('voice.enter_a_phone_number') }}
                              <span v-if="!$root.mobileDevice">{{ $t('voice.on_the_right') }}</span>
                              <span v-else-if="$root.mobileDevice">{{ $t('voice.at_the_top') }}</span>
                              {{ $t('voice.to_start_a_call_in_real_time_on_your_screen') }}
                              <span v-if="!$root.mobileDevice">👉</span>
                              {{ call.contact.full_name }}
                            </span>
                            <span
                              v-else-if="step === 2"
                              v-html="
                                $t('voice.waiting_for_call_to_process', {
                                  twilioNumber: twilioNumber,
                                  userNumber: phone,
                                })
                              "
                            />
                            <span v-else-if="step === 5">
                              {{ $t('voice.the_call_has_been_recorded_to_the_assigned_ticket') }}
                            </span>
                          </div>
                          <div
                            v-for="(message, i) in messages"
                            :key="i"
                            class="flex"
                            :class="{ 'justify-end': !message.outbound }"
                          >
                            <div
                              class="fadeIn mb-2 min-w-0 rounded-lg p-4 leading-normal shadow"
                              :style="{ background: message.outbound ? 'white' : '#D5FFC5' }"
                              style="max-width: 80%; word-break: break-word"
                              v-html="message.message"
                            ></div>
                          </div>
                        </div>
                      </div>

                      <div v-if="step === 4" class="my-2 flex lg:mb-6">
                        <div
                          class="pointer text-center"
                          style="min-width: 60px"
                          :class="{
                            disabled: call.status == 'ringing',
                            'text-white': call.muted,
                            'text-grey-600': !call.muted,
                          }"
                          @click="toggleMute"
                        >
                          <div class="text-center">
                            <i class="material-icons phone-call-icon" v-text="call.muted ? 'mic_off' : 'mic'"></i>
                          </div>
                          <div>
                            <span class="phone-action-title">{{ $t('voice.mute') }}</span>
                          </div>
                        </div>
                        <div
                          class="pointer mx-6 text-center"
                          style="min-width: 60px"
                          :class="{
                            disabled: call.status == 'ringing' || call.intern == true,
                            'text-white': call.hold == true,
                            'text-grey-600': call.hold == false,
                          }"
                          @click="toggleHold"
                        >
                          <div :class="{ 'text-white': call.hold == true }">
                            <i class="material-icons phone-call-icon" v-text="call.hold ? 'play_arrow' : 'pause'"></i>
                          </div>
                          <div>
                            <span class="phone-action-title">{{ $t('voice.pause') }}</span>
                          </div>
                        </div>
                        <div class="pointer text-center" style="min-width: 60px">
                          <div><i class="material-icons phone-call-icon text-grey-800">phone_forwarded</i></div>
                          <div>
                            <span class="phone-action-title" style="color: #303030">{{ $t('voice.forward') }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="phone-call-buttons mb-6 flex justify-center lg:mb-16">
                      <button
                        v-if="step !== 4"
                        class="btn mx-10 flex items-center justify-center bg-orange-dark"
                        role="button"
                        aria-label="End call"
                        :disabled="step !== 3"
                        @click="endCall"
                      >
                        <i class="material-icons text-white" aria-hidden="true">call_end</i>
                      </button>
                      <button
                        v-if="step === 4"
                        class="btn mx-10 flex items-center justify-center bg-red hover:brightness-90"
                        role="button"
                        aria-label="End call"
                        @click="endCall"
                      >
                        <i class="material-icons text-white" aria-hidden="true">call_end</i>
                      </button>
                      <button
                        v-if="step !== 4"
                        class="btn mx-10 flex items-center justify-center bg-green"
                        role="button"
                        aria-label="Accept call"
                        :disabled="step !== 3"
                        @click="acceptInboundCall"
                      >
                        <i class="material-icons text-white" aria-hidden="true">call</i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="step-description flex w-full justify-center">
              <div class="mx-4 mt-12 lg:mx-16 lg:mt-32">
                <div class="voip-sandbox-title mb-2 font-bold text-black">
                  <span v-if="step === 1">{{ $t('voice.experience_voice') }}</span>
                  <span v-else-if="step === 2">{{ $t('voice.call_the_following_number') }}</span>
                  <span v-else-if="step === 3">{{ $t('voice.incoming_call') }}</span>
                  <span v-else-if="step === 4">{{ $t('voice.great') }}</span>
                  <span v-else-if="step === 5">{{ $t('voice.well_done') }}</span>
                </div>
                <div class="voip-sandbox-subtitle font-bold text-green">
                  <span v-if="step === 1">
                    {{ $t('voice.enter_your_phone_number_so_we_can_recognize_your_incoming_phone_call') }}
                  </span>
                  <span v-else-if="step === 2">
                    <a href="tel:31858088215">+{{ twilioNumber }}</a>
                    ☎️
                  </span>
                  <span v-else-if="step === 3">
                    <span v-if="!$root.mobileDevice">👈</span>
                    {{ $t('voice.pick_up_the_phone_call') }}
                  </span>
                  <span v-else-if="step === 4">{{ $t('voice.now_turn_on_your_volume_and_microphone') }}</span>
                  <span v-else-if="step === 5">{{ $t('voice.you_have_made_your_first_call') }}</span>
                </div>
                <p class="voip-sandbox-description my-2 leading-normal lg:my-8">
                  <span
                    v-if="step === 1"
                    v-html="$t('voice.enter_your_phone_number_to_redirect_incoming_call') + '<br />'"
                  />
                  <span v-else-if="step === 2">
                    {{ $t('voice.the_demo_phone_call_will_be_redirected') }}
                    <br />
                  </span>
                  <span v-else-if="step === 3">
                    {{ $t('voice.incoming_phone_call_dont_wait_too_long_to_pick_it_up') }}
                  </span>
                  <span v-else-if="step === 4">
                    {{ $t('voice.with_voice_via_trengo_you_can_make_calls_by_computer_or_mobile_whenever') }}
                  </span>
                  <span v-else-if="step === 5">
                    {{ $t('voice.buy_your_own_number_to_start_calling_with_voice_via_trengo') }}
                  </span>

                  <span v-if="step === 1" class="text-grey-600">
                    {{ $t('voice.note_a_phone_is_required_to_test_this_feature') }}
                  </span>
                  <span v-else-if="step === 2" class="mt-2 block text-grey-600">
                    {{ $t('voice.incorrect_number') }}
                    <u class="pointer" @click="step = 1">{{ $t('voice.try_again') }}</u>
                  </span>
                </p>
                <div v-if="step === 5" class="mt-4 hover:brightness-90 lg:mb-0 lg:mt-8">
                  <router-link
                    :to="'/tickets/' + ticketId"
                    class="btn btn-round primary-button-outline"
                    style="white-space: normal"
                  >
                    {{ $t('voice.listen_to_your_voice_conversation') }}
                    <span v-if="!$root.mobileDevice">{{ $t('voice.in_the_inbox') }}</span>
                  </router-link>
                </div>
                <div v-if="step === 5" class="lg:mb-0">
                  <router-link
                    to="/admin/channels2/voip/create?provider=TWILIO_TRENGO"
                    class="btn btn-round primary-button"
                    style="white-space: normal"
                  >
                    {{ $t('voice.buy_your_own_number') }}
                    <span v-if="!$root.mobileDevice">{{ $t('voice.and_start_calling') }}</span>
                  </router-link>
                </div>
                <div v-if="step === 5" class="mt-2 text-center lg:text-left">
                  <router-link to="/admin/channels2/voip/create?provider=TWILIO" class="text-grey-600">
                    {{ $t('voice.or_add_your_twilio_account_to_start_calling') }}
                  </router-link>
                </div>
                <div v-else-if="step === 1" class="flex w-full items-center justify-center">
                  <form
                    class="voip_sandbox_mobile_input mt-4"
                    :class="{ 'voip_sandbox_mobile_input--error': error, 'voip_sandbox_mobile_input--sent': loading }"
                    @submit.prevent="submit"
                  >
                    <tel-input v-model="phone" @enter="submit"></tel-input>
                    <button
                      type="submit"
                      class="btn btn-round primary-button mt-4 w-full"
                      v-text="loading ? this.$t('voice.sending') : this.$t('voice.provide_demo_number')"
                    ></button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <voip-audio :call="call"></voip-audio>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ArrowTopCircleLinear } from '@trengo/trengo-icons';
import moment from 'moment';
import { mapStores } from 'pinia';
import { defineComponent } from 'vue';

import SettingsLanding from '@/components/common/SettingsLanding';
import { ENTITLEMENT_STATUS, FEATURE, ADDON_NAME, PERMISSION } from '@/Configs/Constants';
import { SETTINGS_URL } from '@/Configs/Constants/navigation/settingsUrl';
import eventBus from '@/eventBus';
import { useEntitlementsStore, useUserStore } from '@/store/pinia';

import CallObject from '../Voip/CallObject';
import Counter from '../Voip/Panel/Timer.vue';
import VoipAudio from '../Voip/Panel/VoipAudio.vue';
import Voip from '../Voip/Voip';
import VoipEvents from '../Voip/VoipEvents';

export default defineComponent({
  name: 'VoipSandbox',
  data() {
    return {
      phone: '',
      error: false,
      loading: false,
      messages: [],
      step: 1,
      twilioNumber: window.MIX_VOIP_SANDBOX_PHONE || '31858088215',
      call: CallObject,
      ticketId: null,
      presentTime: null,
      cancelEndCallTimer: false,
      ENTITLEMENT_STATUS,
      FEATURE,
      ADDON_NAME,
      SETTINGS_URL,
      PERMISSION,
    };
  },
  mixins: [VoipEvents],
  props: {
    title: {
      type: String,
      default: '',
    },
    badge: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    list: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    Counter,
    VoipAudio,
    SettingsLanding,
    ArrowTopCircleLinear,
  },
  computed: {
    ...mapStores(useEntitlementsStore, useUserStore),
    hasPermissionToUpgrade() {
      return this.userStore.hasPermission(PERMISSION.SETTINGS__COMPANY_PROFILE__MANAGE);
    },
    entitlementStatus() {
      return this.entitlementsStore?.getEntitlementStatusFor(FEATURE.CUSTOMER_SERVICE__CHANNELS__VOICE);
    },
    isEntitledToVoice() {
      return this.entitlementStatus === ENTITLEMENT_STATUS.ENTITLED;
    },
    isAddOnBadgeVisible() {
      return (
        this.entitlementStatus === ENTITLEMENT_STATUS.REQUIRES_ADDON ||
        this.entitlementStatus === ENTITLEMENT_STATUS.REQUIRES_ADDON_AND_PLAN_UPGRADE
      );
    },
    computedBadge() {
      return this.isEntitledToVoice ? this.badge : '';
    },
    isConnectButtonVisible() {
      return this.hasPermissionToUpgrade && this.isEntitledToVoice;
    },
    isUnlockButtonVisible() {
      return this.hasPermissionToUpgrade && !this.isEntitledToVoice;
    },
    isHintVisible() {
      return !this.isEntitledToVoice && !this.hasPermissionToUpgrade;
    },
  },
  watch: {
    phone() {
      this.error = false;
    },
    '$root.channels'() {
      this.voip.initialize();
      this.registerEvents();
    },
  },
  mounted() {
    $(this.$refs.modal).on('hidden.bs.modal', () => this.resetSession());
    this.voip = new Voip(AGENCY.channel_prefix, this.$root.user, this.$root.mobileDevice);

    setInterval(() => {
      this.getLocaleTime();
    }, 1000);
  },
  methods: {
    moment: () => moment,
    showCreateModal() {
      $('#CreateVoipModal').modal();
    },
    showSandboxModal() {
      this.call.isVoipSandboxModalOpen = true;
      $(this.$refs.modal).modal();

      eventBus.$on('voip.sandbox_call_started', (call) => {
        if (!this.call.isRingingInbound() && this.step !== 4) {
          this.call.status = 'ringing';
          this.call.direction = 'inbound';
          this.call.token = call.token;
          this.ticketId = call.ticket_id;
          this.$root.notify(call.contact, 'Incoming call');
        }
        this.step = 3;
        setTimeout(() => {
          // clearTimeout doesn't seem to work with eventBus.$on
          if (!this.cancelEndCallTimer) {
            this.endCall();
          }
        }, 15000);
      });

      eventBus.$on('voip.sandbox_call_ended', () => {
        this.endCall();
      });
    },
    submit() {
      if (this.loading) return;
      this.loading = true;
      this.error = false;

      axios
        .post('/api/v2/voip/sandbox', { phone: this.phone })
        .then((res) => {
          this.loading = false;
          if (res.data.error) {
            this.error = true;
          } else if (this.step === 1) {
            eventBus.$emit('channels.reload');
            this.step = 2;
          }
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        });
    },
    resetSession() {
      eventBus.$off('voip.sandbox_call_started');
      eventBus.$off('voip.sandbox_call_ended');
      eventBus.$off('voip.user_call_started');
      eventBus.$off('voip.user_call_ended');

      this.error = false;
      this.loading = false;
      this.phone = '';
      this.call.isVoipSandbox = false;
      this.call.isVoipSandboxModalOpen = false;
      this.step = 1;
      this.cancelEndCallTimer = false;
      this.call.reset();

      // If calling..
      if (this.call.status === 'in_progress') {
        this.endCall();
      }
    },
    getLocaleTime() {
      this.presentTime = moment.tz(this.$root.user.timezone).format('HH:mm');
    },
    acceptInboundCall() {
      this.cancelEndCallTimer = true;
      this.voip.initToken(this.call.channel.id).then((data) => {
        eventBus.$emit('voip.user_call_started');
        this.call.accepting = true;
        this.voip.acceptInboundCall(this.call.token);
        this.voip.sendAcceptedCallEvent(this.call);
        navigator.mediaDevices.getUserMedia({ audio: true }).then(() => {
          this.step = 4;
          this.call.status = 'in_progress';
        });
        setTimeout(() => {
          this.endCall();
        }, 180000);
      });
    },
    endCall() {
      if (this.call.status === 'ringing' || (this.$root.mobileDevice && this.call.status === 'initializing')) {
        $.post('/voip-api/cancelled-call', {
          token: this.call.token,
          mobile: this.$root.mobileDevice,
        });
      }
      eventBus.$emit('voip.user_call_ended');

      this.call.reset();
      this.voip.endCall();

      if (this.step === 3) {
        this.step = 2;
      } else if (this.step === 4) {
        this.step = 5;
      }
    },
    toggleMute() {
      this.call.muted = !this.call.muted;
      this.voip.setMute(this.call.muted);
    },
    toggleHold() {
      if (this.call.status === 'ringing' || this.call.status !== 'in_progress' || this.call.holdLoading) {
        return;
      }

      this.call.muted = !this.call.muted;
      this.voip.setMute(this.call.muted);

      this.call.holdLoading = true;
      this.call.hold = !this.call.hold;

      this.voip.setHold(this.call.hold, this.call.token).then(() => {
        this.call.holdLoading = false;
      });
    },
  },
});
</script>

<style lang="scss">
.voip-sandbox-modal {
  .phone-bg {
    border-radius: 100%;
    width: 120px;
    min-height: 120px;
    opacity: 0.4;
  }

  .phone-container {
    bottom: 0;
  }

  .phone-wrapper {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .phone-bg-face {
    width: 32px;
    height: 32px;
    background: #000;
    border-radius: 100%;
    margin-top: 20px;
  }

  .phone-bg-body {
    width: 70px;
    height: 37px;
    background: #000;
    border-radius: 100%;
    margin-top: 10px;
  }

  .phone-col {
    width: 500px;
  }

  .phone-counter {
    margin-bottom: 1.5rem;
  }

  .phone-number {
    font-size: 22px;
  }

  .phone-description {
    z-index: 1;
    margin-top: -45px;
    opacity: 0.99;
  }

  .phone-call-buttons {
    bottom: 50px;

    button {
      width: 58px;
      height: 58px;
      border-radius: 100%;
      padding: 0;
      line-height: 0;

      i {
        font-size: 34px;
      }
    }
  }

  .phone-title {
    min-height: 100px;

    div {
      font-size: 32px;
    }
  }

  .phone-call-icon {
    font-size: 32px;
  }

  .phone-action-title {
    display: block;
    font-size: 14px;
    margin-top: 4px;
  }

  .modal-dialog {
    max-height: 750px;
  }

  .voip-sandbox-title {
    font-size: 42px;
  }

  .voip-sandbox-subtitle {
    font-size: 32px;
  }

  .modal-centered {
    z-index: 99999;
  }

  @media only screen and (min-width: 1200px) {
    .modal-centered {
      border-radius: 25px !important;
    }
  }

  @media only screen and (max-width: 1199px) {
    border-radius: 0 !important;
  }
}

.voip_sandbox_mobile_input {
  width: 65%;

  @media (max-width: 1199px) {
    width: 100%;
  }

  .vue-tel-input {
    border-radius: 500px !important;
    border: 2px solid rgb(235, 237, 245) !important;

    input {
      padding: 12px 3px 12px 0;
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
    }

    &:focus-within {
      box-shadow: none;
    }

    .dropdown:hover {
      background: none;
    }

    ul {
      border-radius: 20px;
      margin-top: 20px;
      /* width: 390px; */
      border-width: 2px;
      border-color: rgb(235, 237, 245);
    }
  }

  &.voip_sandbox_mobile_input--error {
    .vue-tel-input {
      border-color: red !important;
    }
  }

  &.voip_sandbox_mobile_input--sent {
    .vue-tel-input,
    button {
      pointer-events: none;
      opacity: 0.5;
    }
  }
}

@media (max-width: 991px) {
  .voip-sandbox-modal {
    .phone-bg {
      width: 80px;
      height: 80px;
      min-height: 80px;
    }

    .phone-container {
      height: 48vh;
    }

    .phone-wrapper {
      max-width: 400px;
      width: 85%;
      bottom: 0;
    }

    .phone-wrapper-inner {
      //height:48vh;
      bottom: 0;
    }

    .phone-bg-face {
      width: 22px;
      height: 22px;
    }

    .phone-bg-body {
      width: 50px;
      height: 28px;
    }

    .phone-col {
      width: 100%;
      order: 2;
    }

    .phone-number {
      font-size: 16px;
    }

    .phone-action-title {
      font-size: 12px;
    }

    .phone-description {
      margin-top: -30px;

      div {
        font-size: 14px;
      }
    }

    .phone-call-buttons {
      top: 230px;

      button {
        width: 43px;
        height: 43px;

        i {
          font-size: 24px;
        }
      }
    }

    .phone-call-icon {
      font-size: 20px;
    }

    .phone-title {
      min-height: 0;
      margin-top: -3rem;

      div {
        font-size: 28px;
      }
    }

    .modal-dialog {
      max-height: none;
    }

    .phone-counter {
      margin-bottom: 1rem;
    }

    .step-description {
      position: absolute;
      background: theme('colors.white');
    }

    .voip-sandbox-title {
      font-size: 26px;
    }

    .voip-sandbox-subtitle {
      font-size: 18px;
    }

    .voip-sandbox-description {
      font-size: 14px;
    }
  }
}
</style>

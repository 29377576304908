<script setup lang="ts">
import { useTranslationModalStore } from '@/components/ReplyForm/TranslationOption/useTranslationModalStore';
import { useInboxTranslationStore } from '@/store/pinia/translation/useInboxTranslationStore';

const inboxTranslationModalStore = useTranslationModalStore();
const inboxTranslationStore = useInboxTranslationStore();
</script>

<template>
  <div
    v-if="inboxTranslationModalStore.status === 'LOADING' || inboxTranslationStore.status === 'LOADING'"
    class="flex h-full items-center gap-1"
    data-test="translation-loader"
  >
    <t-spinner class="me-1" size="1rem" />
  </div>
</template>

import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, Fragment as _Fragment, TransitionGroup as _TransitionGroup } from "vue"

const _hoisted_1 = { class: "mb-4" }
const _hoisted_2 = { class: "t-text-desktop-label-md mb-2 text-grey-800 sm:mt-4 md:mt-1" }
const _hoisted_3 = { class: "t-text-desktop-label-md mb-2 mt-6 text-grey-800" }
const _hoisted_4 = { class: "md:flex" }
const _hoisted_5 = { class: "md:mr-1 md:w-1/2" }
const _hoisted_6 = { class: "mt-2 md:ml-1 md:mt-0 md:w-1/2" }
const _hoisted_7 = { class: "t-text-desktop-label-md mb-2 mt-6 text-grey-800" }
const _hoisted_8 = { class: "t-text-desktop-label-md mb-2 mt-6 text-grey-800" }
const _hoisted_9 = { class: "t-text-desktop-label-md mb-2 mt-6 text-grey-800" }
const _hoisted_10 = { class: "mr-0 flex justify-center md:mr-auto" }
const _hoisted_11 = {
  key: "usersLength",
  class: "t-text-sm text-grey-900",
  "data-test": "users-label"
}
const _hoisted_12 = {
  key: "subscriptionUsersQuantity",
  class: "t-text-sm text-grey-700",
  "data-test": "subscription-users-label"
}
const _hoisted_13 = {
  key: "tooltipPlan",
  class: "tooltip-user-modal ml-1.5 flex h-16px w-16px cursor-default items-center justify-center rounded-full bg-grey-600 font-bold text-white hover:bg-grey-800"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loader2_linear = _resolveComponent("loader2-linear")!
  const _component_check_linear = _resolveComponent("check-linear")!
  const _component_atomic_input = _resolveComponent("atomic-input")!
  const _component_t_error_item = _resolveComponent("t-error-item")!
  const _component_info_linear = _resolveComponent("info-linear")!
  const _component_t_inline_banner = _resolveComponent("t-inline-banner")!
  const _component_t_dropdown = _resolveComponent("t-dropdown")!
  const _component_t_multi_select = _resolveComponent("t-multi-select")!
  const _component_alert_triangle_linear = _resolveComponent("alert-triangle-linear")!
  const _component_modal_base = _resolveComponent("modal-base")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createBlock(_component_modal_base, {
    modelValue: _ctx.computedValue,
    "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.computedValue) = $event)),
    "submit-button-text": _ctx.getSubmitButtonText,
    "cancel-button-text": _ctx.$t('user_management.cancel'),
    class: "add-user-modal",
    title: _ctx.$t('user_management.invite_new_user'),
    onSubmit: _ctx.save
  }, _createSlots({
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('user_management.email')), 1 /* TEXT */),
          _createVNode(_component_atomic_input, {
            modelValue: _ctx.email,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.email) = $event)),
            placeholder: _ctx.$t('user_management.agent_at_mailcom'),
            size: "md",
            autofocus: "",
            error: !_ctx.validation.email.email,
            "data-test": "email-input",
            onKeydown: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleKeyboardEvent($event)))
          }, _createSlots({ _: 2 /* DYNAMIC */ }, [
            (_ctx.saving && _ctx.validateFields && !_ctx.validation.email.unique)
              ? {
                  name: "end",
                  fn: _withCtx(() => [
                    _createVNode(_component_loader2_linear, {
                      size: "1rem",
                      class: "button-loading-spinner mx-3 text-grey-600"
                    })
                  ]),
                  key: "0"
                }
              : (_ctx.validateFields && _ctx.validation.email.email && _ctx.validation.email.unique && _ctx.submittedEmail)
                ? {
                    name: "end",
                    fn: _withCtx(() => [
                      _createVNode(_component_check_linear, {
                        size: "1rem",
                        class: "mx-3 text-leaf-500"
                      })
                    ]),
                    key: "1"
                  }
                : undefined
          ]), 1032 /* PROPS, DYNAMIC_SLOTS */, ["modelValue", "placeholder", "error"]),
          (!_ctx.validation.email.email)
            ? (_openBlock(), _createBlock(_component_t_error_item, {
                key: 0,
                text: _ctx.$t('user_management.validation_invalid_email_address'),
                "data-test": "invalid-email-error"
              }, null, 8 /* PROPS */, ["text"]))
            : (!_ctx.validation.email.unique && _ctx.validation.email.email && _ctx.submittedEmail)
              ? (_openBlock(), _createBlock(_component_t_error_item, {
                  key: 1,
                  text: _ctx.$t('user_management.validation_email_already_in_use'),
                  "data-test": "email-in-use-error"
                }, null, 8 /* PROPS */, ["text"]))
              : _createCommentVNode("v-if", true),
          _createVNode(_Transition, { name: "fade" }, {
            default: _withCtx(() => [
              (!_ctx.saving && !_ctx.validation.email.unique && _ctx.validation.email.email && _ctx.submittedEmail)
                ? (_openBlock(), _createBlock(_component_t_inline_banner, {
                    key: 0,
                    class: "mt-3",
                    "data-test": "resend-info"
                  }, {
                    icon: _withCtx(() => [
                      _createVNode(_component_info_linear)
                    ]),
                    default: _withCtx(() => [
                      _createElementVNode("div", null, _toDisplayString(_ctx.$t('user_management.want_to_resend_invite', { email: _ctx.submittedEmail })), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                  }))
                : _createCommentVNode("v-if", true)
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('user_management.name')), 1 /* TEXT */),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_atomic_input, {
                modelValue: _ctx.firstname,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.firstname) = $event)),
                placeholder: _ctx.$t('user_management.first_name'),
                size: "md",
                required: "",
                error: !_ctx.validation.firstname,
                "data-test": "firstname-input",
                onKeydown: _cache[3] || (_cache[3] = ($event: any) => (_ctx.handleKeyboardEvent($event)))
              }, null, 8 /* PROPS */, ["modelValue", "placeholder", "error"]),
              (!_ctx.validation.firstname)
                ? (_openBlock(), _createBlock(_component_t_error_item, {
                    key: 0,
                    text: _ctx.$t('user_management.validation_first_name_required'),
                    "data-test": "firstname-error"
                  }, null, 8 /* PROPS */, ["text"]))
                : _createCommentVNode("v-if", true)
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_atomic_input, {
                modelValue: _ctx.lastname,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.lastname) = $event)),
                placeholder: _ctx.$t('user_management.last_name'),
                size: "md",
                error: !_ctx.validation.lastname,
                "data-test": "lastname-input",
                onKeydown: _cache[5] || (_cache[5] = ($event: any) => (_ctx.handleKeyboardEvent($event)))
              }, null, 8 /* PROPS */, ["modelValue", "placeholder", "error"]),
              (!_ctx.validation.lastname)
                ? (_openBlock(), _createBlock(_component_t_error_item, {
                    key: 0,
                    text: _ctx.$t('user_management.validation_last_name_required'),
                    "data-test": "lastname-error"
                  }, null, 8 /* PROPS */, ["text"]))
                : _createCommentVNode("v-if", true)
            ])
          ]),
          _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t('user_management.user_role')), 1 /* TEXT */),
          (_openBlock(), _createBlock(_component_t_dropdown, {
            key: _ctx.role,
            modelValue: _ctx.role,
            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.role) = $event)),
            items: _ctx.roles(true),
            placeholder: _ctx.$t('user_management.pick_an_option'),
            size: "md",
            "value-index": "value",
            "text-index": "title",
            error: !_ctx.validation.role,
            "container-text-ellipsis": "",
            "data-test": "role-input"
          }, null, 8 /* PROPS */, ["modelValue", "items", "placeholder", "error"])),
          (!_ctx.validation.role)
            ? (_openBlock(), _createBlock(_component_t_error_item, {
                key: 0,
                text: _ctx.$t('user_management.please_select_a_user_role'),
                "data-test": "role-error"
              }, null, 8 /* PROPS */, ["text"]))
            : _createCommentVNode("v-if", true),
          _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.$t('user_management.teams')), 1 /* TEXT */),
          _createVNode(_component_t_multi_select, {
            modelValue: _ctx.team,
            "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.team) = $event)),
            placeholder: _ctx.$t('user_management.select_one_or_more_teams'),
            "use-icon": false,
            "has-avatar": false,
            "is-closable": "",
            data: _ctx.teams,
            size: "md",
            variant: "leaf",
            error: !_ctx.validation.teams,
            "limited-height": "",
            "data-test": "teams-input"
          }, null, 8 /* PROPS */, ["modelValue", "placeholder", "data", "error"]),
          (!_ctx.validation.teams)
            ? (_openBlock(), _createBlock(_component_t_error_item, {
                key: 1,
                text: _ctx.$t('user_management.please_select_a_team'),
                "data-test": "teams-error"
              }, null, 8 /* PROPS */, ["text"]))
            : _createCommentVNode("v-if", true),
          _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$t('user_management.language')), 1 /* TEXT */),
          _createVNode(_component_t_dropdown, {
            modelValue: _ctx.locale_code,
            "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.locale_code) = $event)),
            size: "md",
            placeholder: _ctx.$t('user_management.select_a_language'),
            items: _ctx.languages,
            "value-index": "id",
            "text-index": "title",
            "data-test": "language-input"
          }, null, 8 /* PROPS */, ["modelValue", "placeholder", "items"])
        ])
      ]),
      _createVNode(_Transition, { name: "fade" }, {
        default: _withCtx(() => [
          (!_ctx.hasPermissionToManageSubscription && !_ctx.$userPlan().isTrial)
            ? (_openBlock(), _createBlock(_component_t_inline_banner, {
                key: 0,
                type: "warning",
                class: "sticky bottom-0 z-10",
                "data-test": "user-limit-info"
              }, {
                icon: _withCtx(() => [
                  _createVNode(_component_alert_triangle_linear)
                ]),
                default: _withCtx(() => [
                  _createElementVNode("div", null, _toDisplayString(_ctx.$t('user_management.invitation_user_limit_error')), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              }))
            : _createCommentVNode("v-if", true)
        ]),
        _: 1 /* STABLE */
      })
    ]),
    "footer-left": _withCtx(() => [
      _createElementVNode("div", _hoisted_10, [
        _createVNode(_TransitionGroup, {
          name: "fade",
          class: "flex items-center"
        }, {
          default: _withCtx(() => [
            (_ctx.showUsersAmount)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.usersAmount), 1 /* TEXT */),
                  (_ctx.subscription.quantity)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_12, " /" + _toDisplayString(_ctx.$t('user_management.subscription_quantity_users', { subscription_quantity: _ctx.subscription.quantity })), 1 /* TEXT */))
                    : _createCommentVNode("v-if", true),
                  (!_ctx.hasPermissionToManageSubscription)
                    ? _withDirectives((_openBlock(), _createElementBlock("span", _hoisted_13, [
                        _createTextVNode(" i ")
                      ])), [
                        [_directive_tooltip, {
                placement: 'bottom',
                content: _ctx.$t('user_management.administrator_can_upgrade_subscription_to_add_more_user_seats'),
                popperClass: 'tooltip-vuetify',
                trigger: _ctx.getTooltipEvent,
              }]
                      ])
                    : _createCommentVNode("v-if", true)
                ], 64 /* STABLE_FRAGMENT */))
              : _createCommentVNode("v-if", true)
          ]),
          _: 1 /* STABLE */
        })
      ])
    ]),
    _: 2 /* DYNAMIC */
  }, [
    (_ctx.saving)
      ? {
          name: "button-spinner",
          fn: _withCtx(() => [
            _createVNode(_component_loader2_linear, {
              size: "1rem",
              class: "button-loading-spinner ml-1 text-grey-500"
            })
          ]),
          key: "0"
        }
      : undefined
  ]), 1032 /* PROPS, DYNAMIC_SLOTS */, ["modelValue", "submit-button-text", "cancel-button-text", "title", "onSubmit"]))
}
import { defineStore } from 'pinia';
import { ref } from 'vue';

import { API_INBOX_AI } from '@/components/ReplyForm/api/const';
import { request } from '@/util';

import { useLocales } from './useLocales';

import type { IsTranslationAvailableResponse } from './types';
import type { Locales } from './useLocales';
import type { FetchStatus } from '@/store/pinia/translation/types';

const useModal = () => {
  const isModalOpen = ref(false);

  const toggleModal = () => (isModalOpen.value = !isModalOpen.value);
  const closeModal = () => (isModalOpen.value = false);

  return {
    isModalOpen,
    toggleModal,
    closeModal,
  };
};

export const useTranslationModalStore = defineStore('composerTranslationModal', () => {
  const { locales } = useLocales();
  const { isModalOpen, toggleModal, closeModal } = useModal();

  const status = ref<FetchStatus>('INITIAL');
  const locale = ref<Locales>('EN_GB');
  const prompt = ref('');
  const translation = ref('');
  const ticketId = ref<number>();
  const translations = ref<
    {
      ticket_id?: number;
      locale?: Locales;
      prompt?: string;
      translation?: string;
    }[]
  >([]);

  const isActive = ref(false);

  const getTranslationIndex = (ticketId?: number) => {
    return translations.value.findIndex((t) => t.ticket_id === ticketId);
  };

  const getRevertStatus = () => {
    const index = getTranslationIndex(ticketId.value);
    return translations.value?.[index]?.prompt !== translations.value?.[index]?.translation;
  };

  const setDefaultTexts = (newTicketId: number) => {
    const index = translations.value.findIndex((t) => t.ticket_id === newTicketId);
    translation.value = translations.value[index]?.translation ?? '';
    prompt.value = translations.value[index]?.prompt ?? '';
  };

  const setChannelSupportStatus = async (newTicketId: number) => {
    const { data } = await axios.get<IsTranslationAvailableResponse>(
      `${API_INBOX_AI}/support_translation?ticket_id=${newTicketId}`
    );

    isActive.value = !!data?.is_translation_supported;
    const index = getTranslationIndex(newTicketId);
    if (index !== -1) setDefaultTexts(newTicketId);
  };

  /**
   * Updates the translation payload in the translations array.
   *
   * @param payload - The payload containing the updated translation details.
   */
  const updatePayload = (payload: typeof translations.value[0]) => {
    const index = getTranslationIndex(payload.ticket_id);

    if (index !== -1) {
      translations.value[index] = payload;
    } else {
      translations.value.push(payload);
    }

    prompt.value = payload.prompt ?? '';
    translation.value = payload.translation ?? '';
  };

  /**
   * Fetches the translation for a given payload.
   *
   * @param payload - The payload containing the ticket ID and other translation details.
   * @throws Will throw an error if the ticket ID is not provided in the payload.
   */
  const fetchTranslation = async (payload: typeof translations.value[0]) => {
    if (!payload.ticket_id) throw new Error('Ticket ID is required');
    if (!isActive.value) return;

    try {
      const response = await request<{ original_prompt: string; translated_prompt: string }>(
        API_INBOX_AI + '/translation',
        'POST',
        payload
      );

      const newPayload = {
        ticket_id: payload.ticket_id,
        locale: payload.locale,
        prompt: response.data.original_prompt,
        translation: response.data.translated_prompt,
      };
      updatePayload(newPayload);

      status.value = 'SUCCESS';
    } catch {
      status.value = 'FAILED';
    } finally {
      status.value = 'INITIAL';
    }
  };

  const setTranslation = async (payload: typeof translations.value[0]) => {
    setTimeout(() => {
      closeModal();
    }, 250);
    await fetchTranslation(payload);
  };

  return {
    status,
    locale,
    prompt,
    locales,
    translation,
    ticketId,
    isModalOpen,
    translations,
    closeModal,
    toggleModal,
    getRevertStatus,
    setChannelSupportStatus,
    setTranslation,
  };
});

<script lang="ts" setup>
import { CreditCardLinear } from '@trengo/trengo-icons';

const props = defineProps({
  hasQuerterlyBilling: {
    type: Boolean,
  },
});

const title = props.hasQuerterlyBilling
  ? 'You are currently on a quarterly billing cycle'
  : 'Our payment provider is currently not available';

const subtitle = props.hasQuerterlyBilling
  ? 'To update your subscription, please contact our support.'
  : 'Please try again later, or contact support';
</script>

<template>
  <div
    class="flex h-[500px] flex-col items-center justify-center gap-2 rounded-xl border-1 border-grey-300 bg-white px-4"
  >
    <t-thumbnail class="mb-3 bg-white">
      <credit-card-linear size="1rem" />
    </t-thumbnail>
    <div class="t-text-md-emphasize">{{ title }}</div>
    <div class="t-text-sm">{{ subtitle }}</div>
  </div>
</template>

<script lang="ts" setup>
import { ArrowLeftLinear, ArrowRightLinear } from '@trengo/trengo-icons';
import { storeToRefs } from 'pinia';
import { computed, onMounted } from 'vue';

import AccountPagePanel from '@/components/common/AccountPagePanel/Index.vue';
import MobilePreviewCard from '@/components/common/MobilePreviewCard/MobilePreviewCard.vue';
import EmptyChart from '@/components/EmptyChart';
import { useBillingStore } from '@/store/pinia';
import { isMobile } from '@/util/breakpointHelpers';
import { formatEpochMillisecondDate } from '@/util/date';

import UsageBreakdown from './components/UsageBreakdown.vue';
import { DEFAULT_EMPTY_CHART_PANEL_ITEM } from './constants';
import { useUsageOverviewStore } from './store';

const {
  breakdowns,
  hasUsage,
  isPending: isLoading,
  currentCycleIndex,
  selectedBreakdown,
  isCurrentCycle,
  isUpcoming,
  selectedBreakdownIndex,
  hasError,
} = storeToRefs(useUsageOverviewStore());

onMounted(() => {
  if (isMobile.value) {
    return;
  }
  useUsageOverviewStore().getUsageOverview();
  useBillingStore().getInvoices();
});

const switchCycle = (result: 'backward' | 'forward' | 'current') => {
  switch (result) {
    case 'backward':
      useUsageOverviewStore().setSelectedBreakdownIndex(selectedBreakdownIndex.value - 1);
      break;
    case 'forward':
      useUsageOverviewStore().setSelectedBreakdownIndex(selectedBreakdownIndex.value + 1);
      break;
    case 'current':
    default:
      useUsageOverviewStore().setSelectedBreakdownIndex(currentCycleIndex.value);
      break;
  }
};

const emptyChartDescription = computed(() => {
  switch (true) {
    case hasError.value:
      return 'Please try again later, or contact support';
    case !hasUsage.value && !isLoading.value:
      return 'Your usage of conversations and balance will be displayed here';
    default:
      return '';
  }
});

const dateRange = computed(() => {
  const { startDate, endDate } = selectedBreakdown.value || {};

  if (!startDate || !endDate) {
    return '';
  }

  const firstDay = formatEpochMillisecondDate(startDate, 'dd MMM yyyy');
  const lastDay = formatEpochMillisecondDate(endDate, 'dd MMM yyyy');

  return `${firstDay} - ${lastDay}`;
});
</script>

<template>
  <mobile-preview-card v-if="isMobile" class="max-h-screen w-full">
    {{ $t('general.feature_only_available_on_desktop') }}
  </mobile-preview-card>
  <account-page-panel v-else :title="$t('usage_overview.your_usage_heading')">
    <template #headingRight>
      <div v-if="hasUsage" class="flex items-center gap-2">
        <t-icon-button :disabled="selectedBreakdownIndex === 0" @click="switchCycle('backward')">
          <arrow-left-linear />
        </t-icon-button>
        <span class="t-text-sm w-48 text-center text-grey-600">
          {{ isUpcoming ? $t('usage_overview.upcoming_cycle') : dateRange }}
        </span>
        <t-icon-button :disabled="selectedBreakdownIndex === breakdowns.length - 1" @click="switchCycle('forward')">
          <arrow-right-linear />
        </t-icon-button>
        <t-button :disabled="isCurrentCycle" btn-style="secondary" class="!h-8" @click="switchCycle('current')">
          {{ $t('usage_overview.current') }}
        </t-button>
      </div>
    </template>

    <div class="flex w-full flex-col">
      <div v-show="isLoading" class="mt-6 flex items-center justify-center text-4xl font-extralight">
        <t-spinner />
      </div>
      <usage-breakdown v-if="hasUsage && selectedBreakdown" />
      <template v-if="!hasUsage && !isLoading">
        <empty-chart v-bind="DEFAULT_EMPTY_CHART_PANEL_ITEM" :description="emptyChartDescription" />
      </template>
    </div>
  </account-page-panel>
</template>

<script setup lang="ts">
import { onClickOutside } from '@vueuse/core';
import { ref } from 'vue';

import { useKeyUpCallback } from '@/composables/useKeyUpCallback';
import { useInboxTranslationStore } from '@/store/pinia/translation/useInboxTranslationStore';

import TranslationButton from './TranslationButton.vue';
import TranslationModal from './TranslationModal.vue';
import { useTranslationModalStore } from './useTranslationModalStore';

import type { Locales } from './useLocales';

const props = defineProps<{ ticketId: number; message: string }>();

const emit = defineEmits<{
  (e: 'new-translation', message: string): void;
}>();

const translationModalStore = useTranslationModalStore();
const inboxTranslationStore = useInboxTranslationStore();

inboxTranslationStore.setChannelSupportStatus(props.ticketId);

if (translationModalStore.ticketId !== props.ticketId) {
  translationModalStore.ticketId = props.ticketId;
}

const handleSelection = async (ticket_id: number, locale: Locales, prompt: string) => {
  await translationModalStore.setTranslation({ ticket_id, locale, prompt });
  emit('new-translation', translationModalStore.translation);
};

const wrapper = ref(document.createElement('div'));
onClickOutside(wrapper, () => translationModalStore.closeModal());

useKeyUpCallback('Escape', translationModalStore.closeModal);
</script>

<template>
  <div v-if="inboxTranslationStore.isActive" ref="wrapper" class="relative" data-test="translation-option">
    <translation-button @click="translationModalStore.toggleModal()" />
    <transition name="slide-fade-up">
      <translation-modal
        v-if="translationModalStore.isModalOpen"
        @select-locale="(locale) => handleSelection(ticketId, locale, message)"
      />
    </transition>
  </div>
</template>

<template>
  <div class="w-full min-w-0">
    <div id="sidebarContainer" class="dark-white" style="height: 100%; position: relative">
      <div id="sidebar" class="overflow-y-auto pr-0" style="overflow-y: overlay">
        <div style="margin-top: -1px">
          <div class="mt-4 border-b border-grey-300 pb-4 pr-3">
            <!-- TODO in a separate task -->
            <div class="pull-right">
              <dropdown
                :options="availablePlugins"
                label="label"
                max-height="330px"
                width="250px"
                class="mt-2"
                :close-on-select="false"
              >
                <template #toggle>
                  <i class="sidebar-more-options material-icons mr-3 cursor-pointer text-grey-600">more_horiz</i>
                </template>
                <template #heading>
                  <div class="_500 pt-4 text-center text-base leading-none text-black">
                    <div
                      class="
                        _500
                        hidden-lg-up
                        select-none
                        border-grey-200
                        py-4
                        text-center text-base
                        leading-none
                        text-black
                      "
                    >
                      {{ $t('general.nav_options') }}
                    </div>
                  </div>
                </template>
                <template #option="prop">
                  <label
                    :for="prop.option.id + '_option'"
                    class="flex cursor-pointer"
                    style="margin: -10px; padding: 10px"
                  >
                    <label
                      :for="prop.option.id + '_option'"
                      class="ml-2 flex-1 cursor-pointer text-ellipsis"
                      style="padding-right: 4px; margin: 0"
                    >
                      {{ prop.option.label }}
                    </label>
                    <span class="ml-auto">
                      <label class="ui-switch default" style="margin-top: 3px">
                        <input
                          :id="prop.option.id + '_option'"
                          v-model="selectedPlugins"
                          type="checkbox"
                          :value="prop.option.id"
                          @change="savePlugins($event, prop.option.label)"
                        />
                        <i></i>
                      </label>
                    </span>
                  </label>
                </template>
                <template v-if="userStore.hasPermission(PERMISSION.SETTINGS__APPS_INTEGRATIONS__MANAGE)" #afterList>
                  <router-link to="/admin/developers/plugins/create" class="ml-2">
                    <i class="fa fa-plus text-success mr-1"></i>
                    {{ $t('ticket_sidebar.integration_selector_dropdown_add_app_link') }}
                  </router-link>
                </template>
              </dropdown>
            </div>

            <div
              class="sidebar_block ml-2.5 mt-0 select-text rounded-lg bg-grey-100 px-3 py-4 lg:mt-4"
              style="padding-right: 0"
            >
              <div class="flex items-center">
                <h6 class="mb-0">#{{ ticket.id }}</h6>
                <i
                  style="position: absolute; top: 21px; right: 21px; z-index: 9999"
                  class="sidebar-container-close material-icons ml-auto cursor-pointer text-grey-500"
                  @click="$emit('call-parent-toggleSidebar-method')"
                >
                  close
                </i>
              </div>
              <div class="sidebar_block_body select-none pr-0" style="padding-right: 0; padding-left: 0">
                <div class="contact-block" style="margin-top: 6px">
                  <div class="flex w-full overflow-hidden">
                    <div class="w-3/4 min-w-0 pr-6 pt-4" style="white-space: normal; word-break: break-word">
                      <div class="">
                        <span class="_500 block" data-hj-suppress>
                          <inline-editable
                            mode="inline"
                            input-class="contact-name-inline-editable-width"
                            placement="bottom"
                            :pk="this.ticket.contact_id"
                            :contact_id="this.ticket.contact_id"
                            :model-value="ticket.contact.full_name"
                            @saved="onContactNameUpdated"
                          ></inline-editable>
                        </span>
                      </div>
                      <div class="mt-2">
                        <div class="flex-1">
                          <email-set :contact="ticket.contact"></email-set>
                        </div>
                      </div>
                      <div class="text-muted mb-2 mt-2 min-w-0">
                        <div v-if="ticket.contact.email != null" class="mr-2 min-w-0 flex-1 pb-1">
                          <email-dropdown :contact="ticket.contact"></email-dropdown>
                        </div>
                        <div v-if="ticket.contact.is_phone == true" class="mr-2 min-w-0 flex-1 pb-1">
                          <phone-dropdown :contact="ticket.contact" :voip="canBeCalledWithVoip()"></phone-dropdown>
                        </div>
                        <div v-if="ticket.contact.phone != null && ticket.contact.phone.startsWith('@')" class="pb-1">
                          {{ ticket.contact.phone }}
                        </div>
                      </div>
                    </div>
                    <div class="w-1/4 pl-4" style="height: 76px">
                      <span class="profile_avatar relative z-0">
                        <span class="cursor-pointer" @click="openContact(ticket.contact.id)">
                          <avatar
                            :color="ticket.contact.color"
                            data-hj-suppress
                            :abbr="ticket.contact.abbr"
                            width="96"
                            font-size="3em"
                            :image="ticket.contact.profile_image"
                          ></avatar>
                        </span>
                      </span>
                    </div>
                  </div>

                  <div v-if="!publicProvider" class="py-4 pr-4">
                    <div v-if="profile == null">
                      <!-- Attach profile -->
                      <attach-profile :ticket="ticket" :contact="ticket.contact"></attach-profile>
                    </div>
                    <div v-else>
                      <div class="">
                        <a
                          class="
                            b
                            flex
                            items-center
                            justify-center
                            text-ellipsis
                            rounded
                            bg-white
                            px-4
                            py-3
                            text-center
                          "
                          data-hj-suppress
                          style="text-overflow: ellipsis; overflow: hidden"
                          @click="openProfile"
                        >
                          <i class="material-icons material-icons-xs text-grey-600">portrait</i>
                          <span class="pl-1 text-grey-600">{{ $t('tickets.profile') }}: {{ profile.name }}</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="sidebar_block select-none border-b border-grey-300 p-0">
            <sidebar-collapse collapse-key="collapseTicketSidebarInfoGeneral">
              <template #header>
                <h6 class="mb-0">
                  {{ $t('ticket_sidebar.conversation') }}
                </h6>
              </template>
              <div>
                <div
                  v-if="
                    (ticket.watchers && ticket.watchers.length != 0) ||
                    (ticket.agent && ticket.agent.id != $root.user.id)
                  "
                  class="mt-2"
                >
                  <div class="sidebar-label-title text-grey-500">{{ $t('tickets.watchers') }}</div>
                  <div class="sidebar-label-content mt-2 capitalize text-grey-600">
                    <watchers :ticket="ticket"></watchers>
                  </div>
                </div>

                <div class="mt-2 flex-1">
                  <div class="sidebar-label-title text-grey-500">{{ $t('ticket_sidebar.channel') }}</div>
                  <div class="sidebar-label-content capitalize text-grey-600">
                    <div :style="{ color: ticket.channel.color }" class="flex items-center">
                      <channel-icon
                        :channel="ticket.channel.type"
                        :style="{ color: ticket.channel.color }"
                      ></channel-icon>
                      <div class="flex min-w-0 items-center">
                        <i v-if="ticket.channel.is_private" class="fa fa-lock ml-1 text-grey-500"></i>
                        <span class="ml-2 text-ellipsis">
                          <router-link
                            v-if="userStore.hasPermission(PERMISSION.SETTINGS__CHANNELS__MANAGE)"
                            tag="div"
                            :to="'/admin/channels2/' + ticket.channel.type.toLowerCase() + '/' + ticket.channel.id"
                            class="pointer text-ellipsis capitalize"
                          >
                            {{ ticketChannel }}
                          </router-link>
                          <span v-else class="block text-ellipsis capitalize">{{ ticketChannel }}</span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="mt-2 flex-1">
                  <div class="sidebar-label-title text-grey-500">{{ $t('tickets.status') }}</div>
                  <div class="sidebar-label-content text-grey-600">
                    {{ $t('general.status_' + ticket.status.toLowerCase()) }}
                  </div>
                </div>

                <div v-if="Object.keys($root.ticketResults).length > 0" class="mt-2 flex-1">
                  <div class="sidebar-label-title text-grey-500">{{ $t('tickets.ticket_result') }}</div>
                  <div class="sidebar-label-content capitalize text-grey-600">
                    <span v-if="ticket.results.length > 0">{{ ticket.results[0].name }}</span>
                    <span v-else><em class="">-</em></span>
                  </div>
                </div>
              </div>
            </sidebar-collapse>
          </div>
          <template v-if="ticketLoaded" class="select-none">
            <!-- chat technical details -->
            <chat-tech
              v-if="ticket.channel.type == 'CHAT' && selectedPlugins.includes('chatTech')"
              :ticket="ticket"
              @statusChanged="onChatVisitorStatusChanged"
              @onClientComposing="onClientComposing"
            ></chat-tech>

            <!-- Time Tracking -->
            <time-tracking v-if="selectedPlugins.includes('timeTracking')" :ticket="ticket"></time-tracking>

            <!-- Ticket fields -->
            <template
              v-show="allLoaded"
              v-if="
                ticket &&
                Object.keys($root.customTicketFields).length > 0 &&
                ticket.channel.show_ticket_fields == true &&
                selectedPlugins.includes('ticketFields')
              "
            >
              <ticket-sidebar-custom-field-editor :ticket="ticket"></ticket-sidebar-custom-field-editor>
            </template>

            <!-- Ticket relations -->
            <related-tickets v-if="selectedPlugins.includes('ticketRelations')" :ticket="ticket"></related-tickets>

            <!-- Attachments -->
            <attachments v-if="selectedPlugins.includes('attachments')" :ticket="ticket"></attachments>

            <div v-if="selectedPlugins.some((p) => (' ' + p).includes('contact'))">
              <ticket-sidebar-contact
                :ticket="ticket"
                :contact="ticket.contact"
                :selected-plugins="selectedPlugins"
              ></ticket-sidebar-contact>
            </div>
            <div v-if="selectedPlugins.some((p) => (' ' + p).includes('profile'))">
              <ticket-sidebar-profile
                :ticket="ticket"
                :profile="profile"
                :selected-plugins="selectedPlugins"
              ></ticket-sidebar-profile>
            </div>
          </template>
        </div>
      </div>
      <div
        v-show="showPluginsSidebar"
        v-if="ticketLoaded"
        id="appSidebar"
        class="overflow-y-auto"
        style="overflow-y: overlay"
      >
        <div id="dragBar">
          <span>
            <i class="material-icons">drag_handle</i>
          </span>
        </div>
        <div>
          <div v-for="plugin in pluginsArray" :key="plugin.id" class="sidebar_block border-b border-grey-300">
            <sidebar-collapse :collapse-key="'collapseTicketSidebar_' + plugin.id">
              <template #header>
                <h6 class="mb-0">
                  {{ plugin.name }}
                  <span
                    v-if="multiRequiresOAuth(plugin) && multiIsAuthenticated(plugin)"
                    class="pull-right pointer pl-2 opacity-40"
                    @click="multiLogout(plugin)"
                  >
                    <i class="material-icons text-md text-muted">exit_to_app</i>
                  </span>
                </h6>
              </template>
              <div class="sidebar_block_body select-text">
                <component
                  :is="plugin.type"
                  v-if="multiIsAuthenticated(plugin)"
                  :key="plugin.id"
                  :plugin="plugin"
                  :ticket="ticket"
                  @update-external-url="updateExternalUrl"
                ></component>
                <template v-if="multiRequiresOAuth(plugin) && !multiIsAuthenticated(plugin)">
                  <div
                    class="flex cursor-pointer items-center justify-center pt-2 text-sm font-semibold text-leaf-500"
                    @click="multiAuthenticate(plugin)"
                  >
                    <span>Log in</span>
                    <img :src="$root.assetsURL + 'img/custom-fields/link-open.svg'" class="ml-2 h-15px w-15px" />
                  </div>
                </template>
              </div>
            </sidebar-collapse>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { mapStores } from 'pinia';
import { defineComponent } from 'vue';
import Multiselect from 'vue-multiselect';

import SidebarCollapse from '@/components/common/SidebarCollapse';
import { PERMISSION } from '@/Configs/Constants';
import PluginTypes from '@/Configs/Constants/PluginTypes';
import eventBus from '@/eventBus';
import { useUserStore } from '@/store/pinia';

import VoipCallable from '../../../mixins/VoipCallable';
import avatar from '../../Avatar.vue';
import channelIcon from '../../ChannelIcon.vue';
import inlineEditable from '../../Contacts/ContactNameEditable.vue';
import EmailDropdown from '../../EmailDropdown.vue';
import EmailSet from '../../EmailSet.vue';
import Bol from '../../Integrations/Bol.vue';
import Custom from '../../Integrations/Custom.vue';
import Exact from '../../Integrations/Exact/Generic.vue';
import Hubspot from '../../Integrations/Hubspot/Generic.vue';
import Lightspeed from '../../Integrations/Lightspeed.vue';
import Magento from '../../Integrations/Magento.vue';
import Magento1 from '../../Integrations/Magento1.vue';
import Picqer from '../../Integrations/Picqer.vue';
import Pipedrive from '../../Integrations/Pipedrive/Generic.vue';
import Salesforce from '../../Integrations/Salesforce/Index.vue';
import Shopify from '../../Integrations/Shopify.vue';
import Shopify2 from '../../Integrations/Shopify2.vue';
import Slack from '../../Integrations/Slack.vue';
import Whoocommerce from '../../Integrations/Whoocommerce.vue';
import LabelSelector from '../../LabelSelector.vue';
import PhoneDropdown from '../../PhoneDropdown.vue';
import AttachProfile from '../../Profiles/AttachProfile.vue';
import Dropdown from '../../ReplyForm/Dropdown.vue';
import ticketStatusInfo from '../../TicketStatusInfo.vue';
import timeline from '../../Timeline.vue';
import attachments from '../Attachments.vue';
import chatTech from '../ChatTech.vue';
import ticketSidebarContact from '../Contact.vue';
import ticketSidebarContactCustomFieldEditor from '../ContactCustomFieldEditor.vue';
import ticketSidebarContactGroupEditor from '../ContactGroupEditor.vue';
import ticketSidebarCustomFieldEditor from '../CustomFieldEditor.vue';
import ticketSidebarNotes from '../Notes.vue';
import ticketSidebarProfile from '../Profile.vue';
import relatedTickets from '../RelatedTickets.vue';
import TimeTracking from '../TimeTracking.vue';
import watchers from '../Watchers.vue';

export default defineComponent({
  name: 'TicketSidebar',
  emits: ['statusChanged', 'onClientComposing', 'call-parent-toggleSidebar-method', 'update-ticket'],
  props: {
    ticket: {
      type: Object,
      default: () => ({}),
    },
    publicProvider: {
      type: Boolean,
      default: false,
    },
    ticketLoaded: {
      type: Boolean,
      default: false,
    },
    allLoaded: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      selectedTicketId: null,
      showAttachProfileForm: false,
      plugin: null,
      plugins: [],
      externalUrl: null,
      authenticated: false,
      contact: null,
      contactIsOnline: false,
      draggableInitiated: false,

      selectedPlugins: [
        'attachments',
        'contactCustomFields',
        'contactGroups',
        'contactNotes',
        'contactMoments',
        'profileCustomFields',
        'profileContactMoments',
        'profileNotes',
        'chatTech',
        'ticketRelations',
        'ticketFields',
        // "timeTracking",
        /*"ticketContact",*/
        // "ticketInfo",
      ],
      defaultSidebarItems: [
        {
          type: 'profileNotes',
          label: this.$t('ticket_sidebar.profile_notes'),
        },
        {
          type: 'profileContactMoments',
          label: this.$t('ticket_sidebar.profile_moments'),
        },
        {
          type: 'profileCustomFields',
          label: this.$t('ticket_sidebar.profile_fields'),
        },
        {
          type: 'contactNotes',
          label: this.$t('ticket_sidebar.contact_notes'),
        },
        {
          type: 'contactMoments',
          label: this.$t('ticket_sidebar.contact_moments'),
        },
        {
          type: 'contactGroups',
          label: this.$t('ticket_sidebar.contact_groups'),
        },
        {
          type: 'contactCustomFields',
          label: this.$t('ticket_sidebar.contact_fields'),
        },
        {
          type: 'attachments',
          label: this.$t('ticket_sidebar.attachments'),
        },
        {
          type: 'ticketFields',
          label: this.$t('ticket_sidebar.ticket_fields'),
        },
        {
          type: 'timeTracking',
          label: this.$t('ticket_sidebar.time_tracking'),
        },
        {
          type: 'ticketRelations',
          label: this.$t('ticket_sidebar.linked_conversations'),
        },
        {
          type: 'chatTech',
          label: this.$t('ticket_sidebar.chat_tech'),
        },
        /*{
                        type: 'ticketInfo',
                        label: this.$t('ticket_sidebar.conversation'),
                    },*/
        /*{
                        type: 'ticketContact',
                        label: this.$t('ticket_sidebar.contact'),
                    },*/
      ],
      resizing: false,
      ghostBar: null,
      PERMISSION,
    };
  },

  computed: {
    ...mapStores(useUserStore),
    pluginsArray() {
      return this.plugins.filter((plugin) => plugin);
    },
    profile() {
      if (!this.ticket.contact.profile) {
        return null;
      }
      return this.ticket.contact.profile[0] || null;
    },
    ticketChannel() {
      if (!this.ticket || !this.ticket.channel) {
        return;
      }

      return this.ticket.channel.text;
    },

    availablePlugins() {
      let availablePlugins = this.$root.plugins.map((plugin) => {
        return {
          id: plugin.id,
          type: plugin.type,
          label: plugin.name,
        };
      });
      this.defaultSidebarItems.forEach((sidebarItem) => {
        if (availablePlugins.indexOf(sidebarItem.type) < 0) {
          availablePlugins.unshift({
            id: sidebarItem.type,
            type: sidebarItem.type,
            label: sidebarItem.label,
          });
        }
      });

      return availablePlugins;
    },

    showPluginsSidebar() {
      return (
        this.plugins.filter((plugin) => {
          return !!plugin;
        }).length > 0
      );
    },
  },

  filters: {
    formatDate: function (date) {
      return moment(date).format('DD/MM/YY');
    },
    capitalize: function (string) {
      return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    },
    normalize: function (value) {
      if (!value) return '';
      value = value.replace(/_/g, ' ');
      return value;
    },
  },

  watch: {
    plugin() {
      this.authenticated = false;
      this.externalUrl = null;
    },

    async plugins() {
      if (this.plugins.filter((plugin) => !!plugin).length === 0) {
        document.querySelector('#sidebar').style.setProperty('height', '100%');
        this.unInitDraggablePluginBottom();
      } else {
        document
          .querySelector('#sidebar')
          .style.setProperty('height', (await this.$tStorage.getItem('sidebarHeight')) + '%');
        this.$nextTick(this.initDraggablePluginBottom);
      }
    },
    profile: {
      handler(profile) {
        this.$store.commit('customFields/setTicketHasProfile', !!profile);
      },
      immediate: true,
    },
    ticket: {
      handler(newValue) {
        this.$emit('update-ticket', newValue);
      },
      deep: true,
    },
  },

  mixins: [VoipCallable],

  components: {
    avatar,
    ticketStatusInfo,
    ticketSidebarContactCustomFieldEditor,
    channelIcon,
    ticketSidebarContactGroupEditor,
    ticketSidebarCustomFieldEditor,
    inlineEditable,
    LabelSelector,
    AttachProfile,
    Multiselect,
    Custom,
    Shopify,
    Shopify2,
    Whoocommerce,
    Hubspot,
    Pipedrive,
    Slack,
    Exact,
    Salesforce,
    Lightspeed,
    Magento,
    Magento1,
    Picqer,
    Bol,
    ticketSidebarNotes,
    ticketSidebarContact,
    ticketSidebarProfile,
    timeline,
    SidebarCollapse,
    watchers,
    attachments,
    chatTech,
    relatedTickets,
    TimeTracking,
    EmailSet,
    EmailDropdown,
    PhoneDropdown,
    Dropdown,
  },

  async mounted() {
    this.initPlugins();

    if (this.$route.query.k) {
      axios
        .post('/api/v2/me/plugins?plugin_id=' + (await this.$tStorage.getItem('authenticating_plugin_id')), {
          key: this.$route.query.k,
        })
        .then((res) => {
          this.authenticated = true;
          this.$root.user.plugins.push(res.data);
          router.push('/tickets/' + this.ticket.id);
        });
    }

    this.$nextTick(this.initDraggablePluginBottom);
  },

  beforeUnmount() {
    this.unInitDraggablePluginBottom();
  },

  methods: {
    async initDraggablePluginBottom() {
      if (!document.querySelector('#dragBar')) {
        return;
      }
      if (this.plugins.filter((p) => !!p).length === 0 || this.draggableInitiated) {
        return;
      }
      this.draggableInitiated = true;
      document.addEventListener('mousemove', this.onMouseMove);
      document.addEventListener('touchmove', this.onMouseMove);
      document.addEventListener('mouseup', this.onMouseUp);
      document.addEventListener('touchend', this.onMouseUp);

      document.querySelector('#dragBar').addEventListener('mousedown', this.draggingBar);
      document.querySelector('#dragBar').addEventListener('touchstart', this.draggingBar);
      if ((await this.$tStorage.getItem('sidebarHeight')) && this.$tStorage.getItem('mainHeight')) {
        document
          .querySelector('#sidebar')
          .style.setProperty('height', (await this.$tStorage.getItem('sidebarHeight')) + '%');
        document
          .querySelector('#appSidebar')
          .style.setProperty('height', (await this.$tStorage.getItem('mainHeight')) + '%');
      } else {
        document.querySelector('#sidebar').style.setProperty('height', '70%');
        document.querySelector('#appSidebar').style.setProperty('height', '30%');
      }
    },
    unInitDraggablePluginBottom() {
      this.draggableInitiated = false;
      document.removeEventListener('mousemove', this.onMouseMove);
      document.removeEventListener('touchmove', this.onMouseMove);
      document.removeEventListener('mouseup', this.onMouseUp);
      document.removeEventListener('touchend', this.onMouseUp);
      if (document.querySelector('#dragBar')) {
        document.querySelector('#dragBar').removeEventListener('mousedown', this.draggingBar);
        document.querySelector('#dragBar').removeEventListener('touchstart', this.draggingBar);
      }
    },
    draggingBar(e) {
      e.preventDefault();
      this.dragging = true;
      let main = document.querySelector('#appSidebar');
      let ghostBar = document.createElement('div');
      ghostBar.id = 'ghostBar';
      ghostBar.style.top = main.getBoundingClientRect().top + 'px';
      ghostBar.style.left = main.getBoundingClientRect().left + 'px';
      document.querySelector('#sidebarContainer').appendChild(ghostBar);
    },
    onMouseUp(e) {
      if (this.dragging) {
        let ghostBar = document.querySelector('#ghostBar');
        ghostBar.parentNode.removeChild(ghostBar);

        document.querySelector('#dragBar').classList.remove('hover');
        document.removeEventListener('movingmouse', this.draggingBar);
        this.dragging = false;
      }
    },
    onMouseMove(e) {
      if (this.dragging && document.querySelector('#ghostBar')) {
        let y = typeof e.pageY === 'undefined' ? e.changedTouches[0].pageY : e.pageY;
        document.querySelector('#ghostBar').style.top = y + 'px';
        document.querySelector('#dragBar').classList.add('hover');
        let navBarHeight = 0;
        let percentage = ((y - navBarHeight) / (window.innerHeight - navBarHeight)) * 100;
        if (percentage > 90) {
          percentage = 90;
        }
        if (percentage < 10) {
          percentage = 10;
        }
        let mainPercentage = 100 - percentage;

        document.querySelector('#sidebar').style.setProperty('height', percentage + '%');
        document.querySelector('#appSidebar').style.setProperty('height', mainPercentage + '%');
        this.$tStorage.setItem('sidebarHeight', percentage);
        this.$tStorage.setItem('mainHeight', mainPercentage);
      }
    },
    async initPlugins() {
      let pluginIds = await this.$tStorage.getItem(this.$root.user.id + '_plugins');
      if (Array.isArray(pluginIds)) {
        this.selectedPlugins = pluginIds;
        this.plugins = pluginIds
          .map((pluginId) => {
            return this.$root.plugins.find((p) => {
              return p.id == pluginId;
            });
          })
          .filter((plugin) => {
            return plugin !== null;
          });
      } else {
        // load default
        let pluginIds = this.$root.plugins
          .map((p) => {
            return p.id;
          })
          .filter((plugin) => {
            return plugin !== null;
          });

        let plugins = this.$root.plugins.filter((plugin) => {
          return plugin !== null;
        });

        this.selectedPlugins = [...this.selectedPlugins, ...pluginIds];
        this.plugins = [...plugins];
      }
    },
    savePlugins(e, label) {
      this.$tStorage.setItem(this.$root.user.id + '_plugins', this.selectedPlugins);
      this.initPlugins();
    },

    deletePlugin(pluginId) {
      let index = this.selectedPlugins.indexOf(pluginId);
      if (index > -1) {
        this.selectedPlugins.splice(index, 1);
        this.savePlugins();
      }
    },

    openSelectedTicket: function (e) {
      if (e.target.value == 'null') {
        return;
      }
      this.$router.push('/tickets/' + e.target.value);
    },

    openContact: function (contact_id) {
      this.$router.push('/contacts/' + contact_id);
    },

    openProfile() {
      this.$router.push('/profiles/' + this.profile.id);
    },

    onContactNameUpdated(data) {
      this.ticket.contact.full_name = data.value;
      eventBus.$emit('tickets.list.reload');
    },

    addPlugin() {
      router.push('/admin/developers/plugins/create');
    },

    authenticate() {
      let path = '/api/v2/integrations/oauth/redirects?provider=' + this.plugin.type + '&plugin_id=' + this.plugin.id;

      if (window.isElectron) {
        shell.openExternal('https://app.trengo.com' + path + '&return=' + encodeURIComponent('https://app.trengo.com'));
        return;
      }

      if (window.isLoadedFromApp) {
        parent.postMessage(
          'app-browse-url:' +
            encodeURI('https://app.trengo.com' + path + '&return=' + encodeURIComponent('https://app.trengo.com')),
          '*'
        );
        return;
      }

      window.location.href =
        'https://' + window.location.hostname + '' + path + '&return=' + encodeURIComponent(window.location.href);
    },

    // Multi sidebar
    multiIsAuthenticated(plugin) {
      if (plugin == null) {
        return true;
      }

      if (this.multiRequiresOAuth(plugin)) {
        return (
          this.$root.user.plugins.find((p) => {
            return p.id === plugin.id;
          }) != null
        );
      }

      return true;
    },
    multiRequiresOAuth(plugin) {
      if (plugin == null) {
        return false;
      }

      return [PluginTypes.SLACK, PluginTypes.HUBSPOT, PluginTypes.EXACT, PluginTypes.PIPEDRIVE].includes(plugin.type);
    },
    multiAuthenticate(plugin) {
      let path = '/api/v2/integrations/oauth/redirects?provider=' + plugin.type;

      this.$tStorage.setItem('authenticating_plugin_id', plugin.id);

      if (window.isElectron) {
        shell.openExternal('https://app.trengo.com' + path + '&return=' + encodeURIComponent('https://app.trengo.com'));
        return;
      }

      if (window.isLoadedFromApp) {
        parent.postMessage(
          'app-browse-url:' +
            encodeURI('https://app.trengo.com' + path + '&return=' + encodeURIComponent('https://app.trengo.com')),
          '*'
        );
        return;
      }

      if ([PluginTypes.HUBSPOT, PluginTypes.PIPEDRIVE].includes(plugin.type)) {
        if (['app.trengo.eu', 'web.trengo.eu'].includes(window.location.hostname)) {
          if (
            !confirm(
              'To enable connecting this plugin, you will be forwarded to a different domain (app.trengo.com), then restart authentication'
            )
          ) {
            return;
          }
          window.location.href = 'https://app.trengo.com' + window.location.pathname;
          return;
        }
        if (!window.location.pathname.includes('/tickets/')) {
          alert('Plugin can only be connected from within a conversation');
          return;
        }
      }

      window.location.href =
        'https://' + window.location.hostname + '' + path + '&return=' + encodeURIComponent(window.location.href);
    },
    multiLogout(plugin) {
      axios.delete('/api/v2/me/plugins/' + plugin.id).then((res) => {
        this.$root.user.plugins.splice(this.$root.user.plugins.indexOf(plugin), 1);
        this.externalUrl = null;
      });
    },

    onChatVisitorStatusChanged(status) {
      this.$emit('statusChanged', status);
      this.contactIsOnline = status;
    },

    onClientComposing(text) {
      this.$emit('onClientComposing', text);
    },
    updateExternalUrl(url) {
      this.externalUrl = url;
    },
  },
});
</script>

<style lang="scss">
// Trengo Theme Variables
@import '../../../assets/sass/variables';

.contact-block {
  margin-right: 0;
  .contact-block-name {
    border-radius: $t-border-radius;
  }
}

#dragBar {
  width: 100%;
  margin-left: 0px;
  border-top: 2px solid #f1f1f1;
  color: $t-gray;
  position: absolute;
  height: 3px;
  margin-bottom: -2px;
  z-index: 999;
  cursor: row-resize;
  span {
    background-color: #f1f1f1;
    display: block;
    position: relative;
    top: -15px;
    width: 30px;
    height: 30px;
    line-height: 30px;
    border-radius: 500px;
    text-align: center;
    left: 50%;
    margin-left: -10px;
    opacity: 0.7;
    i {
      line-height: 30px;
    }
  }
  &:hover,
  &.hover {
    border-color: #5fa0f8;
    span {
      background-color: #5fa0f8;
      color: white;
      opacity: 1;
    }
  }
}

#ghostBar {
  width: 320px;
  margin-top: -20px;
  height: 40px;
  background-color: #000;
  opacity: 0;
  position: fixed;
  cursor: row-resize;
  z-index: 9999999999;
}

.latest_message {
  cursor: pointer;
  width: 170px;
  margin-left: 5px;
}
.current-conv {
  color: #101010 !important;
  opacity: 1 !important;
}

.profile_avatar {
  top: -20px;
  right: 7px;
}
.sidebar_block {
  i.collapse_icon {
    opacity: 1;
    vertical-align: middle;
    padding-top: 2px;
  }
}

#sidebarContainer {
  .sidebar_block_body {
    padding: 0 23px;
  }
}
.sidebar_tabs {
  border-radius: 12px;
  background: $t-blue-gray;
  margin: 10px 14px 5px 17px;
  display: flex;
}
.sidebar_block_tab {
  transition: background-color 100ms linear;
  display: inline-block;
  border-radius: 12px;
  margin: 0 0 0 0;
  flex: 1;
  padding: 6px 0;
  border: none;
  background: transparent;
  &:first-of-type {
    margin: 2px 0px 2px 2px;
  }
  &:last-of-type {
    margin: 2px 2px 2px 0;
  }
}
.sidebar_block_tab.active {
  background-color: #fff;
}
.sidebar-label-title text-grey {
}
.sidebar-label-content text-grey-600 {
  i {
  }
  .editable-submit i {
    color: white;
  }
}
.sidebar_text-muted {
  color: $t-gray;
}
.hover-item-hover-background:hover {
  background-color: $t-gray-light;
}
.contact-name-inline-editable-width {
  width: 125px !important;
  height: 27px;
}
</style>

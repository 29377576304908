<script lang="ts">
import { mapStores } from 'pinia';
import { defineComponent } from 'vue';

import { fetchUsers } from '@/api';
import { PERMISSION } from '@/Configs/Constants';
import eventBus from '@/eventBus';
import { useUserStore } from '@/store/pinia';

import Avatar from './Avatar';
import Dropdown from './ReplyForm/Dropdown';
import ticketRepository from '../repositories/Ticket';

export default defineComponent({
  emits: ['assigned', 'dropdown-open', 'handle-assign-to-self', 'handle-assign-to-team'],
  data: function () {
    return {
      assignType: 'user',
      users: [],
      PERMISSION,
    };
  },
  props: {
    ticket: {
      type: Object,
      default: null,
    },
  },

  computed: {
    ...mapStores(useUserStore),
    assignOptions() {
      if (this.assignType === 'team') {
        return this.$root.teams;
      }
      if (this.assignType === 'user') {
        const user = this.$store.getters['initialData/user'];
        const getAssignableUsersByUserId = this.$root.getAssignableUsers(this.users).filter((u) => u.id !== user.id);
        return [this.$root.user, ...getAssignableUsersByUserId];
      } else return {};
    },
  },

  components: {
    Avatar,
    Dropdown,
  },

  watch: {
    ticket: function () {},
  },
  methods: {
    searchUsers(keyword) {
      fetchUsers({ term: keyword }).then((res) => {
        const { data } = res.data;
        this.users = data;
      });
    },
    assignToSelf: function () {
      if (this.ticket) {
        this.$emit('handle-assign-to-self', {
          ticketStatus: 'ASSIGNED',
          agent: this.$root.user,
          userId: this.$root.user.id,
        });
      } else {
        this.$emit('assigned', {
          type: 'user',
          status: 'ASSIGNED',
          agent: this.$root.user,
          user_id: this.$root.user.id,
        });
      }
      ticketRepository.assignTicket(this.ticket.id, this.$root.user.id).then((response) => {
        eventBus.$emit('tickets.modal.assign.submitted', response);
      });
    },

    onAssign(opt) {
      if (this.assignType === 'user') {
        if (this.ticket) {
          this.$emit('handle-assign-to-self', {
            ticketStatus: 'ASSIGNED',
            agent: opt,
            userId: opt.id,
          });
        } else {
          this.$emit('assigned', {
            type: 'user',
            status: 'ASSIGNED',
            agent: opt,
            user_id: opt.id,
          });
        }
        ticketRepository.assignTicket(this.ticket.id, opt.id);
      }

      if (this.assignType === 'team') {
        if (this.ticket) {
          this.$emit('handle-assign-to-team', {
            ticketStatus: 'OPEN',
            team: opt,
            teamId: opt.id,
          });
        } else {
          this.$emit('assigned', {
            type: 'team',
            status: 'OPEN',
            team: opt,
            team_id: opt.id,
          });
        }
        ticketRepository.assignTicketToTeam(this.ticket.id, opt.id);
        window.assigned_team_ticket_id = this.ticket.id;
      }
    },
  },
});
</script>

<template>
  <div class="flex items-center">
    <template
      v-if="
        (ticket.status === 'OPEN' && ticket.team_id == null) ||
        (ticket.status === 'OPEN' && $root.isInTeam(ticket.team_id))
      "
    >
      <a
        v-if="userStore.hasPermission(PERMISSION.INBOX__CONVERSATIONS__ASSIGN)"
        class="
          success
          btn-ticket-action btn-ticket-action--fixed-width
          ml-2
          flex
          cursor-pointer
          items-center
          justify-center
          whitespace-nowrap
          rounded-full
          bg-white
          p-2
          px-3
          pr-4
          text-white
          hover:brightness-90
        "
        data-test="ticket-assign-to-me-btn"
        style="height: 46px"
        @click="assignToSelf"
      >
        <span class="flex items-center">
          <i class="material-icons md-18">person</i>
          <span class="hidden-md-down pl-1">{{ $t('tickets.assign_to_self') }}</span>
        </span>
      </a>
    </template>

    <dropdown
      v-if="userStore.hasPermission(PERMISSION.INBOX__CONVERSATIONS__ASSIGN)"
      :options="assignOptions"
      :dynamic-search="assignType === 'user'"
      :search-action="searchUsers"
      @update:modelValue="onAssign"
      @open="$emit('dropdown-open')"
    >
      <template #heading>
        <div class="_500 pt-4 text-center text-base leading-none text-black">
          {{ $t('tickets.assign_type') }}
        </div>
        <div class="p-4 pb-0">
          <div class="flex items-center justify-center bg-grey-200" style="border-radius: 10px">
            <span
              class="ticket-sub-filter flex-1 text-center text-sm text-grey-600"
              :class="{ 'ticket-sub-filter--active': assignType === 'user' }"
              @click="assignType = 'user'"
            >
              {{ $t('tickets.assign_type_user') }}
            </span>
            <span
              class="ticket-sub-filter flex-1 text-center text-sm text-grey-600"
              :class="{ 'ticket-sub-filter--active': assignType === 'team' }"
              @click="assignType = 'team'"
            >
              {{ $t('tickets.assign_type_team') }}
            </span>
          </div>
        </div>
      </template>
      <template #option="user">
        <div v-if="assignType === 'user'" class="flex items-center">
          <div class="relative">
            <avatar
              width="32"
              :color="user.option.color"
              :abbr="user.option.abbr"
              :image="user.option.profile_image"
            ></avatar>
            <strong
              class="label absolute rounded border border-white"
              style="width: 10px; height: 10px; padding: 0; bottom: 0; right: 0"
              :class="{
                success: user.option.is_online && user.option.user_status === 'ONLINE',
                warning: user.option.is_online && user.option.user_status === 'AWAY',
                danger: !user.option.is_online,
              }"
            ></strong>
          </div>
          <div class="ml-2 leading-none">
            {{ user.option.name }}
          </div>
        </div>
        <div v-if="assignType === 'team'" class="flex items-center">
          <div class="relative">
            <span
              class="circle inline-block-40px avatar flex-shrink-0 bg-grey-200"
              style="width: 32px; height: 32px; line-height: 32px"
            >
              <i class="material-icons text-md leading-none" style="padding-top: 6px">people</i>
            </span>
            <b
              class="label absolute rounded bg-grey-500"
              style="width: 10px; height: 10px; padding: 0; bottom: 0; right: 0"
              :class="{ success: user.option.online_users_count > 0, danger: user.option.online_users_count === 0 }"
            ></b>
          </div>
          <div class="ml-2 leading-none">
            {{ user.option.name }}
            <span v-if="user.option.users_count > 0" class="text-muted p-l-xs text-xs">
              ({{ user.option.online_users_count }})
            </span>
          </div>
        </div>
      </template>
      <template #toggle>
        <div class="selector-conversation-assign flex" role="button">
          <template v-if="ticket.status === 'ASSIGNED' && ticket.user_id != null && ticket.agent != null">
            <a
              class="
                btn-ticket-assign
                ml-2
                flex
                cursor-pointer
                items-center
                justify-center
                rounded-full
                bg-grey-200
                pr-4
                hover:brightness-90
              "
              style="line-height: 1; height: 46px"
            >
              <avatar
                :color="ticket.agent.color"
                width="45"
                :abbr="ticket.agent.abbr"
                :image="ticket.agent.profile_image"
                style=""
              ></avatar>
              <span class="max-w-100 ml-2 text-ellipsis whitespace-nowrap text-grey-600">
                {{ ticket.agent.first_name }}
              </span>
            </a>
          </template>
          <template v-if="ticket.status === 'ASSIGNED' && ticket.user_id != null && ticket.agent == null">
            <span class="hidden-md-down flex items-center">Deleted user</span>
            <a
              class="
                success
                btn-ticket-action
                ml-2
                flex
                cursor-pointer
                items-center
                justify-center
                rounded
                bg-white
                text-white
                hover:brightness-90
              "
              style="height: 46px; line-height: 1; padding: 10px 15px"
            >
              <span class="hidden-md-down">{{ $t('tickets.assign_change') }}</span>
              <span class="hidden-lg-up pl-2"><i class="material-icons md-18">group</i></span>
            </a>
          </template>
          <template v-if="ticket.status === 'ASSIGNED' && ticket.user_id == null">
            <span
              class="
                btn-ticket-action
                ml-2
                flex
                cursor-pointer
                items-center
                justify-center
                rounded-full
                bg-grey-200
                p-2
                px-3 px-4
                pr-4
                hover:brightness-90
              "
              style="height: 46px"
            >
              <span><i class="material-icons md-18 pt-1 text-grey-600">people</i></span>
              <span class="hidden-md-down pl-2 text-grey-600">Chatbot</span>
            </span>
          </template>
          <template v-if="ticket.status === 'OPEN' && ticket.team_id != null">
            <span
              class="
                btn-ticket-action
                ml-2
                flex
                cursor-pointer
                items-center
                justify-center
                rounded-full
                bg-grey-200
                p-2
                px-3 px-4
                pr-4
                hover:brightness-90
              "
              style="height: 46px"
            >
              <span><i class="material-icons md-18 pt-1 text-grey-600">people</i></span>
              <span class="hidden-md-down pl-2 text-grey-600">{{ ticket.team.name }}</span>
            </span>
          </template>
          <template v-if="ticket.status === 'OPEN' && !ticket.team_id">
            <span
              class="
                btn-ticket-action
                ml-2
                flex
                cursor-pointer
                items-center
                justify-center
                rounded-full
                bg-grey-200
                p-2
                px-3 px-4
                pr-4
                hover:brightness-90
              "
              style="height: 46px"
              data-test="ticket-assign-to-other-btn"
            >
              <span><i class="material-icons md-18 pt-1 text-grey-600">group</i></span>
              <span class="hidden-md-down pl-2 text-grey-600">{{ $t('tickets.assign_to_other') }}</span>
            </span>
          </template>
        </div>
      </template>
    </dropdown>
  </div>
</template>

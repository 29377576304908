<script lang="ts" setup>
import { ref } from 'vue';

import ConversationBlocksEditor from './ConversationBlocksEditor.vue';

const props = defineProps<{ blocks: number }>();
const emit = defineEmits(['save', 'close']);
const conversationBlocks = ref(props.blocks);

const handleSave = () => emit('save', conversationBlocks.value);
</script>

<template>
  <t-modal
    :model-value="true"
    :title="$t('edit_subscription.conversation_blocks_title')"
    variant="default"
    @close="emit('close')"
  >
    <div class="h-[400px]">
      <p class="t-text-sm text-grey-600">{{ $t('edit_subscription.conversation_blocks_modal_description') }}</p>
      <form action="POST" @action="handleSave">
        <div>
          <div class="flex flex-col gap-3">
            <conversation-blocks-editor type="standard" :blocks="blocks" @change="conversationBlocks = $event" />
          </div>
        </div>
      </form>
    </div>
    <template #footer>
      <div class="flex w-full justify-end gap-2">
        <t-button btn-style="secondary" @click="emit('close')">
          {{ $t('general.cancel') }}
        </t-button>
        <t-button class="flex items-center" btn-style="primary" @click="handleSave">
          {{ $t('general.save_changes') }}
        </t-button>
      </div>
    </template>
  </t-modal>
</template>

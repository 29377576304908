<template>
  <t-modal v-model="isModalOpen" variant="narrow" title="Edit subscription" @close="$emit('close')">
    <div class="t-text-md mb-6 text-grey-700">
      Please reach out to our support team to modify your subscription details.
    </div>

    <template #footer>
      <t-button btn-style="secondary" @click="$emit('close')">Cancel</t-button>
      <t-button btn-style="primary" href="https://trengo.com/contact" target="_blank" @click="$emit('close')">
        Contact Support
      </t-button>
    </template>
  </t-modal>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
const props = defineProps({
  isOpen: {
    type: Boolean,
    required: true,
  },
});
defineEmits(['close']);

const isModalOpen = computed(() => props.isOpen);
</script>

<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import { useInboxTranslationStore } from '@/store/pinia/translation/useInboxTranslationStore';

import { useTranslationModalStore } from './useTranslationModalStore';

const translationModalStore = useTranslationModalStore();
const inboxTranslationStore = useInboxTranslationStore();

const props = defineProps<{
  message: string;
}>();

const emit = defineEmits<{
  (e: 'revert', message: string): void;
}>();

const revert = () => {
  emit('revert', translationModalStore.prompt);
};

const show = computed(() => inboxTranslationStore.isActive && translationModalStore.translation === props.message);

const { t } = useI18n();
</script>

<template>
  <button v-if="show" type="button" class="absolute z-10 text-grey-600 hover:text-grey-700" @click="revert">
    {{ t('tickets.revert_to_original') }}
  </button>
</template>

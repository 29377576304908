<script lang="ts" setup>
import { TimeLinear, InfoLinear } from '@trengo/trengo-icons';

import { formatEpochDate } from '@/util/date';

defineProps<{ isSubmitted: boolean; hasScheduledChange: boolean; scheduledChangeDate?: number }>();
</script>

<template>
  <div class="mt-3">
    <t-inline-banner v-if="isSubmitted" class="mb-2" type="default-alt">
      <template #icon>
        <info-linear />
      </template>
      <div class="t-text-sm text-grey-800">{{ $t('edit_subscription.your_request_is_processed') }}</div>
    </t-inline-banner>
    <div class="flex justify-between">
      <div
        v-if="hasScheduledChange && scheduledChangeDate && !isSubmitted"
        class="flex w-full items-center gap-2 text-sky-600"
      >
        <time-linear size="1.2rem" />
        <span class="t-text-sm-emphasize">
          {{ $t('edit_subscription.scheduled_change') }} {{ formatEpochDate(scheduledChangeDate) }}
        </span>
      </div>
      <slot name="right"></slot>
    </div>
  </div>
</template>
